import React from 'react'
import { Flex, HStack } from '@chakra-ui/react'

interface TicketHeaderProps {
    children: React.ReactNode | React.ReactNode[]
}

export function TicketHeaderBox({ children }: TicketHeaderProps) {
    return (
        <Flex
            w="full"
            h={"40px"}
            position={"sticky"}
            top={"50px"}
            zIndex={100}
            bgColor={"#EBEEFF"}
            alignItems={"center"}
        >
            <HStack w={"90%"} p={2} gap={4} alignItems={"center"} mx="auto" justifyContent={"space-between"} maxW={"1440px"} >
                {children}
            </HStack>
        </Flex >
    )
}
