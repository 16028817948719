
import DatePicker from 'react-datepicker';
import { useLocation } from "react-router-dom";
import { sortSelectOptionsByLabel } from '../../app/utils';
import { FilterValues } from "../../features/tickets/tickets";
import { KnownIcon } from "../../components/widgets/KnownIcon";
import { DetailHeading } from "../../components/DetailHeading";
import { selectAllUsers } from '../../features/users/usersSlice';
import { useAppSelector, useConfigCheck } from "../../app/hooks";
import { CustomButton } from "../../components/forms/CustomButton";
import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { selectAllCustomers } from "../../features/customers/customersSlice";
import { TICKET_STATUS_OPTIONS } from "../../features/tickets/ticket_choices";
import { selectAllActivities } from "../../features/activities/activitySlice";
import { selectAllCoordinates } from '../../features/terminals/coordinatesSlice';
import Select, { ActionMeta, MultiValue, SingleValue, StylesConfig } from 'react-select';
import { Box, InputGroup, InputLeftAddon, HStack, Card, CardHeader, Divider, CardBody, Text, Stack, StackDivider, useMediaQuery, Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody, DrawerFooter, ButtonGroup, VStack, Switch, Flex, Button, Input, calc } from "@chakra-ui/react";

interface ListViewProps {
    filters: FilterValues
    filterApplied: boolean
    setFilters?: Dispatch<SetStateAction<FilterValues>>;
    handleApplyFilter?(): void
    handleResetFilter?(): void
    isOpen: boolean;
    onClose: () => void;
}
type OptionType = { value: string | number; label: string; };
type EntryType = "ticketTime" | "nonTicketTime" | "all";

const ticketEntryTypeOption: { label: string; value: EntryType }[] = [
    { label: "All", value: "all" },
    { label: "Ticket ", value: "ticketTime" },
    { label: "Non-Ticket", value: "nonTicketTime" }
]
const approvalTypeOptions: { label: string; value: boolean | null }[] = [
    { label: "All", value: null },
    { label: "Approved", value: true },
    { label: "Unapproved", value: false }
]

export function ListView({
    isOpen,
    onClose,
    filters,
    filterApplied,
    handleApplyFilter,
    handleResetFilter,
    setFilters = () => { }, // default value
}: ListViewProps) {
    const { isOnline } = useConfigCheck()
    const { userProfile: { role } = {} } = useConfigCheck() //get role of current user
    const pathName = useLocation().pathname
    const customers = useAppSelector(selectAllCustomers) // get all customers
    const lsds = useAppSelector(selectAllCoordinates) // get all lsds
    const activities = useAppSelector(selectAllActivities) // get all activities
    const allUsers = useAppSelector(selectAllUsers) // get all users
    const { activity, dateRange, is_approved, customer, status, entryType, lsd, user } = filters
    const [startDate, endDate] = dateRange;
    const [isLargerThanMobile] = useMediaQuery('(min-width: 768px)')

    // Memoize customer options
    const customerSelectOptions: OptionType[] = useMemo(() =>
        sortSelectOptionsByLabel(
            customers
                // .filter(customer => customer?.profile)
                .map(customer => ({ value: customer.id, label: customer.name }))
        ), [customers]);


    const userSelectOptions: OptionType[] = useMemo(() =>
        sortSelectOptionsByLabel(allUsers
            .filter((item) => !!(item.id && (item.first_name?.trim() || item.last_name?.trim())))
            .map((item) => ({
                value: item.id || 0,
                label: `${item.first_name || ''} ${item.last_name || ''}`.trim()
            }))
        ), [allUsers]);


    const lsdSelectOptions: any[] = useMemo(() =>
        lsds.map(lsd => ({ value: lsd, label: lsd })), [lsds]);

    const customerRoleSelectOptions = useMemo(() => {
        return TICKET_STATUS_OPTIONS.filter(option =>
            option.value !== "submitted" && option.value !== "unapproved"
        )
    }, [])

    const getActivityOptions = useCallback(() => {
        return activities
            .filter((item) => entryType === "all" || item.description_field === (entryType === "nonTicketTime"))
            .map((item) => ({ value: item.name, label: item.name }));
    }, [activities, entryType]);

    const isApproveTime = (role !== "customer" && role !== "driver") && pathName.includes("/user/approve-time-entries");
    const isTimeTrackingPage = pathName.includes("user/tracking");
    const selectValue = isApproveTime ? activity : status;
    const selectPlaceholder = isApproveTime ? "Select Activity" : "Select Ticket Status";
    const selectOptionsValues = isApproveTime ? getActivityOptions() : TICKET_STATUS_OPTIONS;
    const selectOptions = sortSelectOptionsByLabel(selectOptionsValues);

    const handleSelectChange = (value: SingleValue<OptionType> | MultiValue<OptionType>) =>
        setFilters(prev => ({ ...prev, [isApproveTime ? 'activity' : 'status']: value || prev[isApproveTime ? 'activity' : 'status'] }));

    const handleSelectLSDChange = (
        newValue: SingleValue<OptionType> | null,
        actionMeta: ActionMeta<OptionType>
    ) => {
        setFilters(prev => ({
            ...prev,
            lsd: newValue, // Update the state with the selected value or null
        }));
    };

    const handleCustomerChange = (
        newValue: SingleValue<OptionType> | null,
        actionMeta: ActionMeta<OptionType>
    ) => {
        setFilters(prev => ({
            ...prev,
            customer: newValue, // Update the state with the selected value or null
        }));
    };

    const handleUserChange = (
        newValue: SingleValue<OptionType> | null,
        actionMeta: ActionMeta<OptionType>
    ) => {
        setFilters(prev => ({
            ...prev,
            user: newValue, // Update the state with the selected value or null
        }));
    };

    const handleTicketType = (value: "ticketTime" | "nonTicketTime" | "all") =>
        setFilters((prev) => { return { ...prev, entryType: value, activity: [], customer: null } })

    const handleApprovalType = (value: boolean | null) =>
        // setFilters((prev) => { return { ...prev, is_approved: value } })
        setFilters((prev) => { return { ...prev, is_approved: value } })

    const handleReset = () => {
        handleResetFilter?.()
    };

    const handleFilter = () => {
        handleApplyFilter?.();
        onClose();
    };

    const customStyles: StylesConfig<OptionType, boolean> = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: state.isFocused ? '#3182ce' : '#E2E8F0',
            boxShadow: state.isFocused ? '0 0 0 1px #3182ce' : '',
        }),
        placeholder: (baseStyles) => ({
            ...baseStyles,
            fontSize: '1rem',
            color: '#758399',
        }),
    };

    const filterContent = (
        <VStack alignItems={'stretch'} gap={3}>
            {
                isApproveTime &&
                <Box w="full"  >
                    <ButtonGroup isAttached w="full" >
                        {ticketEntryTypeOption.map((item, index) => (
                            <CustomButton key={index} fontSize={"12px"} variant={entryType === item.value ? "solid" : "outline"} w={"full"} title={item.label} onClick={(e) => handleTicketType(item.value)} />
                        ))}
                    </ButtonGroup>
                </Box>
            }
            {isApproveTime && (
                <Box w="full">
                    <ButtonGroup isAttached w="full">
                        {approvalTypeOptions.map((item, index) => (
                            <CustomButton
                                key={index}
                                fontSize={"12px"}
                                variant={is_approved === item.value ? "solid" : "outline"}
                                w={"full"}
                                title={item.label}
                                onClick={() => handleApprovalType(item.value)}
                            />
                        ))}
                    </ButtonGroup>
                </Box>
            )}
            <Stack color="#0E1628" divider={<StackDivider />} spacing='3'>
                {
                    isApproveTime && (entryType === "ticketTime" || entryType === "all") &&
                    <VStack w="full" alignItems={'stretch'} gap={0}>
                        <Text fontWeight={500} mb={1}>Select Customer</Text>
                        <Select
                            styles={customStyles}
                            value={customer ?? null}
                            placeholder="Select Customer"
                            options={customerSelectOptions}
                            onChange={(newValue: MultiValue<OptionType> | SingleValue<OptionType>, actionMeta: ActionMeta<OptionType>) => {
                                return handleCustomerChange(newValue as SingleValue<OptionType>, actionMeta);
                            }}
                            isSearchable={true}
                            isMulti={false}
                        />
                    </VStack>
                }
                {
                    isApproveTime &&
                    <VStack w="full" alignItems={'stretch'} gap={0}>
                        <Text fontWeight={500} mb={1}>Select User</Text>
                        <Select
                            styles={customStyles}
                            value={user ?? null}
                            placeholder="Select User"
                            options={userSelectOptions}
                            onChange={(newValue: MultiValue<OptionType> | SingleValue<OptionType>, actionMeta: ActionMeta<OptionType>) => {
                                return handleUserChange(newValue as SingleValue<OptionType>, actionMeta);
                            }}

                            isSearchable={true}
                            isMulti={false}
                        />
                    </VStack>
                }
                {
                    !isTimeTrackingPage &&
                    <VStack w="full" alignItems={'stretch'} gap={0}>
                        <Text fontWeight={500} mb={1}>
                            {isApproveTime ? "Select Activity" : "Ticket Status"}
                        </Text>
                        <Select
                            styles={customStyles}
                            value={selectValue ?? null}
                            placeholder={selectPlaceholder}
                            options={role !== 'customer' ? selectOptions : customerRoleSelectOptions}
                            onChange={(newValue: MultiValue<OptionType> | SingleValue<OptionType>, actionMeta: ActionMeta<OptionType>) => handleSelectChange(newValue)}
                            isSearchable={false}
                            isMulti={true}
                        />
                    </VStack>
                }
                {
                    (!isTimeTrackingPage && !isApproveTime) &&
                    <VStack w="full" alignItems={'stretch'} gap={0}>
                        <Text fontWeight={500} mb={1}>Select LSD</Text>
                        <Select
                            styles={customStyles}
                            value={lsd ?? null}
                            placeholder="Select LSD"
                            options={lsdSelectOptions}
                            onChange={(newValue: MultiValue<OptionType> | SingleValue<OptionType>, actionMeta: ActionMeta<OptionType>) => {
                                return handleSelectLSDChange(newValue as SingleValue<OptionType>, actionMeta);
                            }}
                            isSearchable={true}
                            isMulti={false}
                        />
                    </VStack>
                }
                <VStack w="full" alignItems={'stretch'} gap={0}>
                    <Text fontWeight={500} mb={1}>
                        Date Range
                    </Text>
                    <Box id="root-portal">
                        <InputGroup>
                            <InputLeftAddon p={2} bgColor="#fff"   >
                                <KnownIcon name="calendar" boxSize={"24px"} />
                            </InputLeftAddon>
                            <DatePicker
                                placeholderText="Select Date Range"
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={(update) => {
                                    setFilters(prev => ({ ...prev, dateRange: update }))
                                }}
                                isClearable={true}
                                maxDate={new Date()}
                                portalId="root-portal"
                                customInput={
                                    <Input
                                        borderTopLeftRadius={0}
                                        borderBottomLeftRadius={0}
                                        _focus={{ borderColor: "#3182ce", boxShadow: "0 0 0 1px #3182ce", outline: "none" }}
                                        _hover={{ borderColor: 'hsl(0, 0%, 70%);' }}
                                        backgroundColor={"#fff"}
                                        inputMode='none'
                                    />
                                }
                            />
                        </InputGroup>
                    </Box>
                </VStack>
            </Stack>
        </VStack>
    )

    return (
        <>
            {
                isLargerThanMobile ?
                    (
                        <Card
                            // className='custom-scrollbar'
                            w={{ base: "min-content", lg: "full" }}
                            minW="270px"
                            maxW="320px"
                            position="sticky"
                            top={
                                role === "swamper" && isTimeTrackingPage
                                    ? "160px"
                                    : isTimeTrackingPage
                                        ? "180px"
                                        : "100px"
                            }
                        // maxH="calc(100vh - 100px)"
                        // overflowY={"auto"}
                        >
                            <CardHeader p={{ base: 3, lg: 4 }}>
                                <HStack justifyContent={"space-between"}>
                                    <DetailHeading title="Filter" />
                                    {filterApplied && <Text color="#E02020" fontSize="12px" cursor="pointer" onClick={handleReset}> Clear all </Text>}
                                </HStack>
                            </CardHeader>
                            <Divider color="#F0F0F0" />
                            <CardBody p={{ base: 3, lg: 4 }}>
                                {filterContent}
                                <CustomButton
                                    mt={4}
                                    w={"80px"}
                                    h={"36px"}
                                    title="Apply"
                                    variant="outline"
                                    onClick={handleFilter}
                                    isDisabled={!isOnline}
                                />
                            </CardBody>
                        </Card >)
                    : (
                        <Drawer
                            isOpen={isOpen}
                            placement='right'
                            onClose={onClose}
                            size="xs"
                            allowPinchZoom={true}
                            closeOnOverlayClick={false}
                            closeOnEsc={false}
                        >
                            <DrawerOverlay />
                            <DrawerContent bgColor={"#F8F8FB"}>
                                <DrawerCloseButton />
                                <DrawerHeader bgColor={"#EBEEFF"}>
                                    <DetailHeading title='Filter' />
                                </DrawerHeader>
                                <DrawerBody>
                                    {filterContent}
                                </DrawerBody>
                                <DrawerFooter bgColor={"#fff"}>
                                    <ButtonGroup gap={["20px", "25px"]}>
                                        {
                                            filterApplied &&
                                            <CustomButton
                                                title="Clear all"
                                                variant="outline"
                                                colorScheme="red"
                                                onClick={handleReset}
                                            />
                                        }
                                        <CustomButton title="Apply" onClick={handleFilter} isDisabled={!isOnline} />
                                    </ButtonGroup>
                                </DrawerFooter>
                            </DrawerContent>
                        </Drawer>
                    )

            }
        </>
    )
}
