import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    UnorderedList,
    ListItem,
    Box,
} from "@chakra-ui/react";
import { AlertStatus } from "../app/hooks";

interface MessageBoxProps {
    show: boolean;
    status: AlertStatus;
    message?: string | string[];
    error?: any;
}

const formatMessage = (text: string): string => {
    if (!text) return "";
    return text
        .split(/\r?\n/)
        .filter(line => line.trim())
        .join("<br/>");
};

export function MessageBox({ show, status, message, error }: MessageBoxProps) {
    return show ? (
        <Alert status={status} rounded="md">
            <AlertIcon />
            <Box>
                <AlertTitle>
                    {!Array.isArray(message) ? (
                        <Box
                            dangerouslySetInnerHTML={{
                                __html: formatMessage(message || "")
                            }}
                            sx={{
                                'br': {
                                    display: 'block',
                                    content: '""',
                                    marginTop: '0.5rem'
                                }
                            }}
                        />
                    ) : (
                        <>
                            {message.map((item, index) => (
                                <ListItem key={index}>
                                    <Box
                                        dangerouslySetInnerHTML={{
                                            __html: formatMessage(item)
                                        }}
                                        sx={{
                                            'br': {
                                                display: 'block',
                                                content: '""',
                                                marginTop: '0.5rem'
                                            }
                                        }}
                                    />
                                </ListItem>
                            ))}
                        </>
                    )}
                </AlertTitle>
                {error?.data?.detail && (
                    <AlertDescription>
                        {error.data.detail}
                    </AlertDescription>
                )}
            </Box>
        </Alert>
    ) : null;
}