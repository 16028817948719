import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FilterValues } from "./tickets";

interface PageFilterState {
    timeTrackingFilters: FilterValues | null;
    ticketListFilters: FilterValues | null;
    approveTimeFilters: FilterValues | null;
  }
  
  const initialState: PageFilterState = {
    timeTrackingFilters: null,
    ticketListFilters: null,
    approveTimeFilters: null
  };
  
  export const filterSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
      savePageFilters: (state, action: PayloadAction<{ 
        page: 'timeTracking' | 'ticketList' | 'approveTime',
        filters: FilterValues 
      }>) => {
        const { page, filters } = action.payload;
        switch (page) {
          case 'timeTracking':
            state.timeTrackingFilters = filters;
            break;
          case 'ticketList':
            state.ticketListFilters = filters;
            break;
          case 'approveTime':
            state.approveTimeFilters = filters;
            break;
        }
      },
      clearPageFilters: (state, action: PayloadAction<string>) => {
        switch (action.payload) {
          case 'timeTracking':
            state.timeTrackingFilters = null;
            break;
          case 'ticketList':
            state.ticketListFilters = null;
            break;
          case 'approveTime':
            state.approveTimeFilters = null;
            break;
        }
      },
      clearAllFilters: (state) => {
        state.timeTrackingFilters = null;
        state.ticketListFilters = null;
        state.approveTimeFilters = null;
      },
    }
  });


  export const {
    savePageFilters,
    clearPageFilters,
    clearAllFilters
  } = filterSlice.actions


  export default filterSlice.reducer;