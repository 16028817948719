import React, { useMemo } from "react";
import { FieldProps } from "../../forms";
import { SelectField } from "../../components/forms/fields/SelectField";

interface BillingToSelectProps extends FieldProps {
  isRequired?: boolean
  disabled?: boolean
  contactCustomers?: { contact_id: number; first_name?: string; last_name?: string }[]
  defaultBillingCustomer?: number | null
}

const BillingToSelect: React.FC<BillingToSelectProps> = ({
  contactCustomers = [],
  defaultBillingCustomer,
  disabled = false,
  ...props
}) => {
  // Transform contact customers into options for the select field
  const options = useMemo(() => {
    return contactCustomers
      .filter((item) => !!(item.first_name?.trim() || item.last_name?.trim()))
      .map((item) => ({
        value: item.contact_id,
        label: `${item.first_name || ''} ${item.last_name || ''}`.trim()
      }));
  }, [contactCustomers]);
  return (
    <SelectField
      {...props}
      options={options}
      disabled={disabled}
      isRequired={props.isRequired}
      id={defaultBillingCustomer || undefined}
      name="billingCustomer"
    />
  )
}

export default BillingToSelect;