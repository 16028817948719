import { useConfigCheck } from "../app/hooks";
import { TicketHeaderBox } from "./TicketHeaderBox";
import { CustomButton } from "./forms/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonGroup, useMediaQuery } from "@chakra-ui/react";
import { BreadCrumbs, BreadcrumbItemType } from "./BreadCrumbs";
import { Mode } from "../pages/stamp/StampTicketDetail";

type StampTicketHeaderProps = {
    isLoading: boolean
    ticketStatus: string;
    isDigitalSigned: boolean;
    breadCrumbItems: BreadcrumbItemType[];
    onOpen: (mode: Mode) => void;

};
// enum Mode {
//     CodeOnly = 'code_only',
//     CodeAndStamp = 'code_and_stamp',
//   }


export function StampTicketHeader({ isLoading, breadCrumbItems, ticketStatus, isDigitalSigned, onOpen }: StampTicketHeaderProps) {
    const { id } = useParams()
    const { isOnline } = useConfigCheck()
    const [isLargerThanMobile] = useMediaQuery("(min-width: 992px)")
    return (
        !isLoading &&
        <TicketHeaderBox>
            <BreadCrumbs items={breadCrumbItems} />
            {
                isLargerThanMobile &&
                <ButtonGroup>
                    {
                        ticketStatus === "approved" && (
                            <CustomButton
                                title="Reject"
                                isDisabled={!isOnline}
                                onClick={() => onOpen(Mode.Reject)}
                                ml={'0.5rem'}
                            />
                        )
                    }
                    {(ticketStatus === "approved" || ticketStatus === "coded") && (
                        <>
                            <CustomButton
                                title="Code Only"
                                isDisabled={!isOnline}
                                onClick={() => onOpen(Mode.CodeOnly)}
                                mx={0}
                            />
                            <CustomButton
                                title="Code and Stamp"
                                isDisabled={!isOnline}
                                onClick={() => onOpen(Mode.CodeAndStamp)}
                                mx={0}
                            />
                        </>
                    )}

                    {/* {(ticketStatus === "approved" || ticketStatus === "coded") && (
                        )} */}
                    <CustomButton mx={0} title="Print" href={isOnline ? `/print/tickets/${id}?withCharge=${true}` : undefined} isDisabled={isOnline ? false : true} />
                </ButtonGroup>
            }
        </TicketHeaderBox >
    )
}