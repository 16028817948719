import { Box } from "@chakra-ui/react";
import { DraftExtraValues } from "./tickets";
import { PickUpPanelForm } from "./PickUpPanelForm";
import { forwardRef, useEffect, useRef } from "react";
import { Container } from "../../components/Container";
import { EditForm } from "../../components/forms/EditForm";
import { PanelWrapper } from "../../components/PanelWrapper";
import { OverlayLoader } from "../../components/OverlayLoader";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { TicketResource } from "../../pages/user/TicketDetailPage";
import { CustomButton } from "../../components/forms/CustomButton";
import { saveDraftPickUp, selectPickUp } from "../draft/draftSlice";
import { WrapperProps } from "../../components/TicketDetailWrapper";
import { useAppDispatch, useAppSelector, useCustomToast, } from "../../app/hooks";
import { removeTicketTankGaugeRecord, selectTicketPickUpDetail, updateTicketPickUpDetail } from "./ticketsSlice";
import { isDraft } from "@reduxjs/toolkit";

interface ProductPanelProps extends WrapperProps {
    dataresource?: TicketResource,
    scrollTo?(data: string): void
}

export const PickUpPanel = forwardRef<HTMLDivElement, ProductPanelProps>(
    function PickUpPanel_NEW({ dataresource, isdraft, resourceid, scrollTo }: ProductPanelProps, ref) {
        const dispatch = useAppDispatch();
        const { showToast } = useCustomToast();
        const isManualReset = useRef(false);

        //Selector based on draft or ticket
        const selectResource = isdraft ?
            useAppSelector(selectPickUp(Number(resourceid)))
            : useAppSelector(selectTicketPickUpDetail(Number(resourceid))) || {}

        const methods = useForm<DraftExtraValues>({ defaultValues: selectResource })
        const { control, handleSubmit, formState: { isSubmitting }, reset } = methods
        // Watch form values to detect changes
        const watchedValues = useWatch<DraftExtraValues>({
            control,
            defaultValue: selectResource,
        })
        const hasChanged = JSON.stringify(watchedValues) !== JSON.stringify(selectResource)

        /* Save handler based on draft or ticket
         * Save to local storage whenever watched values change for draft (AutoSave)
         */
        const handleSaveResource = async (data: DraftExtraValues) => {
            //check valid tank gauge readings
            const { tank_gauge_details, ...rest } = data || {};
            const payload = {
                ...rest,
                tank_gauge_details: tank_gauge_details?.filter(
                    item => item.start && item.finish
                )
            };

            if (!isdraft) {
                try {
                    await dispatch(updateTicketPickUpDetail({ id: Number(resourceid), ...payload })).unwrap();
                    isManualReset.current = true;
                    // Scroll to the drop-off form after the action is completed
                    scrollTo?.("dropOffForm");
                } catch (error) {
                    console.error("Action failed:", error);
                    showToast({
                        status: 'error',
                        description: "Something went wrong.",
                    });
                }
            }
        };
        //auto save handler for draft page
        useEffect(() => {
            if (isdraft) {
                const allTankGaugeValid = watchedValues?.tank_gauge_details?.filter(
                    item => {
                        const start = item?.start ?? "";
                        const finish = item?.finish ?? "";

                        return typeof start === "string" && start.trim() !== "" ||
                            typeof finish === "string" && finish.trim() !== "";
                    }
                );
                dispatch(saveDraftPickUp({ id: Number(resourceid), ...watchedValues, tank_gauge_details: allTankGaugeValid } as DraftExtraValues));
            }
        }, [watchedValues, resourceid, dispatch, isdraft]);

        // Tank gauge remove handler
        const handleRemoveTankGaugeRecord = async (index: number) => {
            const tank_gauge_id = dataresource?.tank_gauge_details && dataresource?.tank_gauge_details[index]?.id
            await dispatch(removeTicketTankGaugeRecord({ id: Number(resourceid), tank_gauge_id: Number(tank_gauge_id) }))
        }

        // reset form after save event to track any new change in form
        useEffect(() => {
            if (isManualReset.current) {
                reset(selectResource);
            }
            isManualReset.current = false;
        }, [selectResource, reset, methods]);

        return (
            <Box w="full" ref={ref}>
                {isSubmitting && <OverlayLoader />}
                <Container>
                    <FormProvider {...methods}>
                        <EditForm onSave={handleSubmit(handleSaveResource)}>
                            <PanelWrapper title="Load">
                                {hasChanged && !isdraft && <CustomButton title="Save" type="submit" variant="outline" w="80px" isLoading={isSubmitting} />}
                                <PickUpPanelForm onRemove={!isdraft ? handleRemoveTankGaugeRecord : undefined} />
                            </PanelWrapper>
                        </EditForm>
                    </FormProvider>
                </Container >
            </Box>
        )
    })
