import { Box } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { Draft, selectDraftTimeEntries } from "../draft/draftSlice";
import { useAppSelector, useCustomToast } from "../../app/hooks";
import { selectAllUsers } from "../users/usersSlice";
import { Container } from "../../components/Container";
import DriverHoursForm from "../draft/DriverHoursForm";
import { PanelWrapper } from "../../components/PanelWrapper";
import { useRetrieveTicketQuery } from "../tickets/ticketsApi";
import { forwardRef, useEffect, useMemo, useState } from "react";
import { selectTicketTimeEntries } from "../tickets/ticketsSlice";
import { WrapperProps } from "../../components/TicketDetailWrapper";
import { useCreateTimeEntryMutation, useDestroyTimeEntryMutation, usePartialUpdateTimeEntryMutation } from "./timeEntriesApi";
import { addLocalEntry, LocalEntry, removeLocalEntriesbyActivity, selectLocalEntries, updateLocalEntry } from "./localEntriesSlice";
import { OverlayLoader } from "../../components/OverlayLoader";
interface AdditionalItemsPanelProps extends WrapperProps {
    dataresource?: Draft
}

function convertToLocalEntryFormat(data: Array<any>): LocalEntry[] {
    return data.map(item => ({
        id: item.id,
        activity: item.activity.id,
        start: item.start,
        finish: item.finish,
        ticketDraft: item.ticket,
        worker: item.worker,
        is_approved: item.is_approved
    }));
}


export const DriverHoursPanel = forwardRef<HTMLDivElement, AdditionalItemsPanelProps>(
    function DriverHoursPanel({ dataresource = {} as Draft, resourceid, isdraft }: AdditionalItemsPanelProps, ref) {
        const dispatch = useDispatch();
        const { showToast } = useCustomToast();
        const allUsers = useAppSelector(selectAllUsers);

        const { number } = dataresource;

        const [createTimeEntry] = useCreateTimeEntryMutation();
        const [partialUpdateTimeEntry] = usePartialUpdateTimeEntryMutation();
        const [destroyTimeEntry] = useDestroyTimeEntryMutation();
        const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

        //check for there is time entries in current draft
        const [draftDriverHourEntries, setDraftDriverHourEntries] = useState<LocalEntry[]>([])
        const localEntries = useAppSelector(selectLocalEntries)
        const filteredTimeEntries = useMemo(() => localEntries.filter((item: LocalEntry) => item.ticketDraft === dataresource?.number), [dataresource?.number, localEntries])

        const ticketTimeEntries: any = useAppSelector(selectTicketTimeEntries(Number(resourceid)))
        useEffect(() => {
            setDraftDriverHourEntries(isdraft ? filteredTimeEntries : convertToLocalEntryFormat(ticketTimeEntries));
        }, [isdraft, filteredTimeEntries, ticketTimeEntries]);// this does not have id 

        const handleTimeEntrySave = async (data: any) => {
            setIsSubmitting(true)
            try {
                if (isdraft) {
                    // Draft page logic - use local entries
                    const existingEntry = localEntries.find(
                        entry =>
                            entry.activity === data.activity &&
                            entry.ticketDraft === dataresource?.number
                    );
                    if (existingEntry) {
                        // Update existing local entry

                        dispatch(updateLocalEntry({
                            ...data,
                            ticketDraft: dataresource?.number
                        }));
                    } else {
                        // Add new local entry
                        const selectedUser: any = allUsers.find((item) => item.id == data.worker)
                        const { first_name, last_name, role } = selectedUser;
                        data = { ...data, first_name, last_name, role }
                        dispatch(addLocalEntry({ ticketDraft: dataresource?.number, ...data }))
                    }
                } else {
                    if (data.id) {
                        // Update existing time entry in the database
                        const { worker, ...rest } = data
                        await partialUpdateTimeEntry({ id: data.id, ...rest })
                    } else {
                        // Create new time entry in the database
                        const { id: removedId, ...cleanData } = data;
                        cleanData.ticket = resourceid;
                        await createTimeEntry(cleanData).unwrap();
                    }
                }
            }
            catch (error) {
                console.error("Action failed:", error);
                showToast({
                    status: 'error',
                    description: "Something went wrong",
                });
            } finally {
                setIsSubmitting(false);
            }
        }

        //remove handler
        const handleTimeEntryRemove = (activity: number) => {
            if (isdraft) {
                const filteredEntries = localEntries.filter((item) => {
                    return !(item.activity == activity && item.ticketDraft == number)
                })
                dispatch(removeLocalEntriesbyActivity(filteredEntries))
            } else {
                const existingEntry = ticketTimeEntries.find(
                    (entry: any) => entry.activity.id === activity
                );
                destroyTimeEntry(existingEntry.id)
            }
        }


        // //Selector based on draft or ticket
        // const selectResource = (isdraft ?
        //     useAppSelector(selectDraftTimeEntries(Number(resourceid)))
        //     : useAppSelector(selectTicketTimeEntries(Number(resourceid)))) || {}

        // console.log(selectResource);

        // console.log(draftDriverHourEntries);


        return (
            <Box w="full" >
                {isSubmitting && <OverlayLoader />}
                <Container>
                    <PanelWrapper title="Hours">
                        <DriverHoursForm timeEntries={draftDriverHourEntries} onSave={handleTimeEntrySave} onRemove={handleTimeEntryRemove} isdraft={isdraft} />
                    </PanelWrapper>
                </Container>
            </Box >
        )
    })
