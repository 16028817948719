import { bolmintApi } from "../../app/api";
import { operations } from "../../app/api/schema";


export const usersApi = bolmintApi.injectEndpoints({
    endpoints: (builder) => ({
        listUsers: builder.query<
            operations["users_list"]["responses"]["200"]["content"]["application/json"],
            void
        >({
            query: () => ({
                url: `/users/`,
            }),
        }),
    })
})

export const {
    useListUsersQuery
} = usersApi
