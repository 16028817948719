import { useEffect, useState } from "react";
import { KnownIcon } from "./widgets/KnownIcon";
import { useConfigCheck, useDebounce } from "../app/hooks";
import { Input, InputGroup, InputRightAddon } from "@chakra-ui/react";
import AnimatedPlaceholders from "./forms/fields/AnimatedPlaceholders";

interface SearchInputProps {
  placeholders?: string[]
  searchTerm?: string
  handleSearch(data: string): void
}

export function SearchInput({ handleSearch, placeholders = [], searchTerm }: SearchInputProps) {
  const { isOnline } = useConfigCheck();
  const [searchText, setSearchText] = useState<string>(searchTerm || '');
  const debouncedValue = useDebounce(searchText, 500);  // debouncer hook

  //trigger search method 
  useEffect(() => {
    if (debouncedValue.length >= 3) {
      handleSearch(debouncedValue)
    } else {
      handleSearch("")
    }
  }, [debouncedValue])

  return (
    <InputGroup
      position={"relative"}
      minW={"245px"}
      maxW={{ base: "full", sm: "full", md: "auto" }}
    >
      {/* Animated Placeholder */}
      {searchText.trim().length === 0 && ( // Only show the animated placeholder when the input is not focused
        <AnimatedPlaceholders placeholders={placeholders} />
      )}
      {/* Actual Input with Hidden Placeholder */}
      <Input
        onChange={(e) => setSearchText(e.target.value)}
        value={searchText}
        backgroundColor="#fff"
        isDisabled={!isOnline}
        _focus={{ zIndex: 1 }}
        zIndex="1" // Ensure the input field is behind the animated placeholder
      />

      {/* Right Addon with Icon */}
      <InputRightAddon h="2.5rem" p={2} bgColor="#fff"
        cursor={!isOnline ? "not-allowed" : "auto"} // Disable interactions when offline
        opacity={!isOnline ? 0.5 : 1} >
        <KnownIcon name="magnifier" boxSize="24px" />
      </InputRightAddon>
    </InputGroup >
  )
}
