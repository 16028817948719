import { FieldProps } from "../../forms";
import { useAppSelector } from "../../app/hooks";
import { selectAllEquipments } from "./equipmentsSlice";
import { SelectField } from "../../components/forms/fields/SelectField";

interface EquipmentSelectProps extends FieldProps {
  setIsValue(data: number | null): void
}

export function EquipmentSelect(props: EquipmentSelectProps) {
  const equipments = useAppSelector(selectAllEquipments)
  const options = equipments
    .map(({ id, unit, category }) => ({
      value: id,
      label: `${unit} (${category?.name})`,
      categoryName: category?.name?.toLowerCase() || ''
    }))
    .sort((a, b) => a.categoryName.localeCompare(b.categoryName));
  return <SelectField w={{ base: "70%", md: "50%" }} {...props} options={options} setIsValue={props.setIsValue} />
}
