import { FieldProps } from "../../../forms";
import { useFormContext } from "react-hook-form";
import { FormControl, FormLabel, FormHelperText, FormErrorMessage, InputGroup, Input, VStack } from "@chakra-ui/react";

interface NumberFieldProps extends FieldProps {
  isEdit?: boolean;
}

export function NumberField({
  label,
  name,
  registerOptions,
  helper,
  readOnly,
  placeholder,
  isEdit = false
}: NumberFieldProps) {
  const { register, formState: { errors }, setValue, watch } = useFormContext();

  const handleDecimalRounding = (value: string | number | undefined) => {
    if (value === "" || value == null) {
      return null;
    }

    const numValue = Number(value);

    // Handle special case for sw_percent field
    if (name === 'sw_percent' && !isNaN(numValue)) {
      const decimalStr = numValue.toString().split('.')[1];

      if (decimalStr && decimalStr.length > 3) {
        // Round to 3 decimal places and update the field
        const roundedValue = Number(numValue.toFixed(3));
        // Use setTimeout to avoid React state update during render
        setTimeout(() => setValue(name, roundedValue), 0);
        return roundedValue;
      }
    }

    return numValue;
  };

  const updatedRegisterOptions = {
    ...registerOptions,
    setValueAs: handleDecimalRounding,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      // Allow original onChange if it exists
      if (registerOptions?.onChange) {
        registerOptions.onChange(e);
      }

      // Handle the decimal rounding on change
      if (name === 'sw_percent' && e.target.value) {
        const value = parseFloat(e.target.value);
        if (!isNaN(value)) {
          const decimalStr = e.target.value.split('.')[1];
          if (decimalStr && decimalStr.length > 3) {
            e.target.value = value.toFixed(3);
          }
        }
      }
    }
  };

  return (
    <FormControl isInvalid={errors[name] !== undefined}>
      <VStack w="full" gap="10px" alignItems="flex-start">
        <FormLabel fontWeight={500} color={"#455360"}>
          {label} {registerOptions?.required && <span style={{ color: 'red' }}>*</span>}
        </FormLabel>
        <InputGroup>
          <Input
            {...register(name, updatedRegisterOptions)}
            id={name}
            h={"48px"}
            borderRadius={"4px"}
            color={"#0E1628"}
            variant={"filled"}
            type="number"
            inputMode="decimal"
            placeholder={placeholder}
            readOnly={readOnly}
            step="any"
            autoFocus={!(watch(name)?.length > 0) && isEdit}
          />
        </InputGroup>
        {helper && <FormHelperText>{helper}</FormHelperText>}
        <FormErrorMessage>{errors[name]?.message?.toString()}</FormErrorMessage>
      </VStack>
    </FormControl>
  );
}