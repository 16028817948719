import { Dispatch, Fragment, SetStateAction, useState } from 'react';
import { Link } from 'react-router-dom';
import { stringifyDate } from '../app/utils';
import { useConfigCheck } from '../app/hooks';
import { KnownIcon } from './widgets/KnownIcon';
import { StatusBadges } from './widgets/StatusBadges';
import { TicketData } from '../pages/user/TicketsPage';
import { Link as ChakraLink, Checkbox } from '@chakra-ui/react';
import { Box, Card, CardBody, Flex, HStack, Spacer, Stack, StackDivider, Text, VStack } from '@chakra-ui/react';

interface TicketCardProps {
    ticket: TicketData
    selectedTickets?: number[]
    select?: Dispatch<SetStateAction<number[]>>
}

export function TicketCard({ ticket, select, selectedTickets }: TicketCardProps) {
    const { userProfile: { role } = {} } = useConfigCheck()
    const { isOnline } = useConfigCheck();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.target
        if (checked) {
            select?.((prev) => [...prev, ticket.id]);
        } else {
            select?.((prev) => prev.filter((id) => id !== ticket.id));
        }
    }
    return (
        <Fragment >
            <Card w="full">
                <CardBody p={{ base: 2, lg: 5 }}>
                    {/* select checkbox  */}
                    {/* {
                        (ticket?.status === "approved" || ticket?.status === "coded") &&
                        <Checkbox
                            size='lg'
                            css={{
                                '.chakra-checkbox__control': {
                                    '&:hover': {
                                        color: '#3455FF',
                                        background: 'transparent', // Ensure it overrides the default hover background
                                    },
                                    '&[data-checked]:hover': {
                                        backgroundColor: '#3455FF',
                                        borderColor: '#3455FF',
                                        color: 'white',
                                    },
                                    '&[data-checked]': {
                                        backgroundColor: '#3455FF',
                                        borderColor: '#3455FF',
                                        color: 'white',
                                    },
                                },
                            }}
                            isChecked={selectedTickets?.includes(ticket.id)}
                            onChange={(e) => handleChange(e)}
                        />
                    } */}
                    <Stack p={2} divider={<StackDivider />} spacing='4' color={"#0E1628"}>
                        <HStack justifyContent={"space-between"}>
                            <HStack>
                                <Text fontSize={{ base: "12px", sm: "18px" }} fontWeight={600}>#{ticket?.number}</Text>
                                {
                                    role === "driver-lead" && ticket?.driver_lead_add_charges &&
                                    <KnownIcon name="redDollar" />
                                }
                            </HStack>
                            <Flex gap={2} color="#3455FF" fontSize="12px">
                                <ChakraLink as={Link} to={role === 'customer' ?
                                    `/user/stamp/tickets/${ticket?.id}`
                                    : `/user/tickets/${ticket?.id}`}
                                    cursor={!isOnline ? "not-allowed" : "pointer"}
                                    _hover={{ textDecoration: "none" }}
                                // pointerEvents={(!isOnline || selectedTickets?.length !== 0) ? "none" : undefined}
                                > View Details
                                </ChakraLink>
                                <KnownIcon name="link" />
                            </Flex>
                        </HStack>
                        <HStack w="full" gap={3} justifyContent={"space-between"}>
                            <VStack alignItems={"flex-start"} gap={3} >
                                <Flex gap={{ base: "6px", lg: 3 }} alignItems={"center"}>
                                    <KnownIcon name="customer" boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                    <Text
                                        whiteSpace="wrap"
                                        overflow="hidden"
                                        maxW={{ base: "180px", sm: "250px", lg: "400px" }}
                                        fontSize={{ base: "14px", sm: "16px", lg: "18px" }} fontWeight={500}
                                        isTruncated
                                    >
                                        {ticket?.customer}
                                    </Text>
                                </Flex>
                                <Flex gap={{ base: "6px", lg: 3 }} alignItems={"center"}>
                                    <KnownIcon name="driver" boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                    <Text
                                        whiteSpace="wrap"
                                        overflow="hidden"
                                        maxW={{ base: "180px", sm: "250px", lg: "400px" }}
                                        fontSize={{ base: "14px", sm: "16px", lg: "18px" }} fontWeight={500}
                                        isTruncated
                                    >
                                        {ticket?.driver}
                                    </Text>
                                </Flex>
                            </VStack>
                            <VStack alignItems={"flex-end"} gap={3} >
                                <Flex gap={{ base: "6px", lg: 3 }} alignItems={"center"}>
                                    <KnownIcon name="calendar" boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                    <Text fontSize={{ base: "12px", lg: "16px" }}  >{stringifyDate(ticket?.reference_date)}</Text>
                                </Flex>
                                <StatusBadges status={ticket?.status} />
                            </VStack>
                        </HStack>
                        {
                            (ticket?.consignor_name || ticket?.consignee_name) &&
                            <HStack color="#0E1628" spacing={4} alignItems={"center"}>
                                <VStack w="fit-content" minW="100px" maxW="35%" alignItems={'stretch'}>
                                    <Text fontSize={{ base: 12, md: 14 }}>From</Text>
                                    <Flex gap={{ base: "6px", lg: 3 }} alignItems="flex-start">
                                        <KnownIcon name="location" boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                        <Box w="full" wordBreak="break-word" whiteSpace="normal" overflow="hidden">
                                            <Text
                                                fontWeight={500}
                                                noOfLines={2}
                                                fontSize={[12, 14, 16]}
                                            >
                                                {ticket?.consignor_name || "--"}
                                            </Text>
                                            <Text fontWeight={500} noOfLines={2} fontSize={[12, 14, 16]} color={"#666E82"}>{ticket?.consignor_lsd || "--"} </Text>
                                            <Text fontWeight={500} noOfLines={2} fontSize={[12, 14, 16]} color={"#666E82"}>{ticket?.consignor_location || "--"} </Text>
                                        </Box>
                                    </Flex>
                                </VStack>
                                <Spacer
                                    borderBottom="2px dashed #9F9F9F"
                                    alignSelf="center"
                                />
                                <VStack w="fit-content" minW="100px" maxW="35%" alignItems={'stretch'}>
                                    <Text fontSize={{ base: 12, md: 14 }}>To</Text>
                                    <Flex gap={{ base: "6px", lg: 3 }} alignItems="flex-start">
                                        <KnownIcon name="location" boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                        <Box w="full" wordBreak="break-word" whiteSpace="normal" overflow="hidden">
                                            <Text
                                                fontWeight={500}
                                                noOfLines={2}
                                                fontSize={[12, 14, 16]}
                                            >
                                                {ticket?.consignee_name || "--"}
                                            </Text>
                                            <Text fontWeight={500} noOfLines={2} fontSize={[12, 14, 16]} color={"#666E82"}>{ticket?.consignee_lsd || "--"} </Text>
                                            <Text fontWeight={500} noOfLines={2} fontSize={[12, 14, 16]} color={"#666E82"}>{ticket?.consignee_location || "--"} </Text>
                                        </Box>
                                    </Flex>
                                </VStack>
                            </HStack>
                        }
                    </Stack>
                </CardBody>

                {/* effect on select */}
                {selectedTickets?.includes(ticket.id) && (
                    <Box
                        position="absolute"
                        top="0"
                        left="0"
                        width="100%"
                        height="100%"
                        bg="rgba(0, 0, 0, 0.1)"
                        zIndex="1"
                        pointerEvents="none"
                        borderRadius="md"
                        animation="fadeIn 0.3s ease-in-out"
                        sx={{
                            "@keyframes fadeIn": {
                                "0%": { opacity: 0 },
                                "100%": { opacity: 1 },
                            },
                        }}
                    />
                )}
            </Card >
        </Fragment >
    )
}
