import { Box, HStack, StackProps, Text, VStack } from "@chakra-ui/react";
import { KnownIcon, KnownIconName } from "./widgets/KnownIcon";
import { ReactNode } from "react";


interface DetailTileProps extends StackProps {
    icon?: KnownIconName
    title?: string
    detail?: string | ReactNode
}

export function DetailTile({
    icon,
    title,
    detail,
    ...rest
}: DetailTileProps) {
    return (
        <VStack align="stretch" gap={{ base: 2, lg: 4 }} flexBasis={"30%"} {...rest}>
            {title && <Text fontSize="14px" color="#455360" >{title}</Text>}
            <HStack gap={3} alignItems='flex-start'>
                {icon && <KnownIcon name={icon} boxSize={"24px"} />}
                <Box
                    w="full"
                    fontSize={{ base: "16px", lg: "18px" }}
                    wordBreak="break-word"
                    whiteSpace="normal"
                    noOfLines={2}
                    overflow="hidden"
                >
                    {detail ?? '--'}
                </Box>
            </HStack>
        </VStack>
    )
}
