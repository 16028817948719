
import { FormControl, FormLabel, Switch } from "@chakra-ui/react"


interface ReadonlyBooleanFieldProps {
    label?: string
    isChecked: boolean
}

export function ReadOnlyBooleanField({ label, isChecked }: ReadonlyBooleanFieldProps) {
    return (
        <FormControl >
            <FormLabel color="#455360">{label}</FormLabel>
            <Switch id={label} defaultChecked={isChecked}
                sx={{
                    '.chakra-switch__track': {
                        cursor: 'not-allowed',
                        bg: 'gray.300', // background when off
                    },
                    '.chakra-switch__track[data-checked]': {
                        bg: '#3455FF', // custom background color when on
                    },
                }}
                readOnly
            />
        </FormControl>
    )
}