import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { RootState } from "./store"
import { selectToken } from "./auth"
import { Mutex } from "async-mutex"
export interface ApiError {
  status: number
  data: { detail: string }
}

function getCsrfTokenFromCookies() {
  const cookies = document.cookie.split(";")
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim()
    // Check if this cookie starts with the CSRF token name
    if (cookie.startsWith("csrftoken=")) {
      // Return the substring after the CSRF token name
      return cookie.substring("csrftoken=".length)
    }
  }
  // If cookie not found, return null or handle appropriately
  return null
}

const baseQuery = fetchBaseQuery({
  baseUrl: `${import.meta.env.VITE_API_URL || ""}/api`,
  credentials: "include",
  prepareHeaders: (headers, { getState, endpoint }) => {
    const excludeHeadersEndpoints = ["uploadFileToS3", "getCompConfig"] //<-- List of endpoints where headers should be excluded
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    // Check if the current endpoint is in the list
    if (!excludeHeadersEndpoints.includes(endpoint)) {
      const token = selectToken(getState() as RootState)
      const csrfToken = getCsrfTokenFromCookies()
      if (token) headers.set("Authorization", token)
      if (csrfToken) headers.set("X-CSRFToken", csrfToken)
      if (
        endpoint === "driverTimeEntry" ||
        endpoint === "listingPaginatedTicketData" ||
        endpoint === "paginatedTimeEntries" ||
        endpoint === "paginatedDriverTimeEntries"
      )
        headers.set("TimeZone", timeZone)
      return headers
    }
  },
})

const mutex = new Mutex()
const baseQueryWithReauth = async (
  args: any,
  api?: any,
  extraOptions?: any,
) => {
  return mutex.acquire().then(async (release) => {
    let result = await baseQuery(args, api, extraOptions)
    if (result.error && result.error.status === 403) {
      localStorage.clear()
      window.location.href = "/auth/logout"
    } else {
      release()
    }
    return result
  })
}

export const bolmintApi = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    pingServer: builder.mutation<any, void>({
      query: () => ({
        url: "/ping/",
      }),
    }),
  }),
  tagTypes: [
    "ticket",
    "attachments",
    "locations",
    "terminals",
    "time_entries",
    "signature",
    "customers",
    "coordinates"
  ],
  refetchOnReconnect: true,
})

export default bolmintApi.reducer
