import { InputField } from "../../components/forms/fields/InputField"
import { NumberField } from "../../components/forms/fields/NumberField"

interface ChargesFormProps {
  isSurCharge?: boolean
  isEdit?: boolean
}

export function ChargesForm({ isSurCharge, isEdit }: ChargesFormProps) {
  return (
    <>
      <InputField label="Description" name="description" readOnly={isSurCharge ? true : false} placeholder="Write Name" registerOptions={{ required: "Add charge description" }} />
      <NumberField isEdit={isEdit} label="Quantity" name="quantity" placeholder="Write Quantity " registerOptions={{ required: "Add quantity being charged" }} />
      <InputField label="Unit" name="unit" readOnly={isSurCharge ? true : false} placeholder="Write Unit " registerOptions={{ required: "Add charge unit" }} />
      <NumberField isEdit={isEdit} label="Rate" name="rate" readOnly={isSurCharge ? true : false} placeholder="Write rate " registerOptions={{ required: "Add charge rate" }} />
    </>
  )
}
