import { useState } from "react";
import { ChargesForm } from "../charges/ChargesForm";
import { selectTicketServices } from "./ticketsSlice";
import { Container } from "../../components/Container";
import { PanelWrapper } from "../../components/PanelWrapper";
import { ModalForm } from "../../components/forms/ModalForm";
import { selectAllServices } from "../services/servicesSlice";
import { useListServicesQuery } from "../services/servicesApi";
import { Draft, selectDraftServices } from "../draft/draftSlice";
import { TicketResource } from "../../pages/user/TicketDetailPage";
import { CustomButton } from "../../components/forms/CustomButton";
import { ChargeWrapperProps } from "../../components/ChargeDetailWrapper";
import { useAppSelector, useConfigCheck, useScrollTracker } from "../../app/hooks";
import { Box, Table, Tbody, Td, Th, Thead, Tr, useDisclosure, useMediaQuery } from "@chakra-ui/react";

export interface TicketServicesOverviewProps extends ChargeWrapperProps {
  dataresource?: TicketResource | Draft | null
}

export function TicketServicesOverview({
  isdraft,
  resourceId,
  dataresource,
  handlesavecharge
}: TicketServicesOverviewProps) {
  useListServicesQuery()

  const [isLargerThanMobile] = useMediaQuery("(min-width: 768px)");
  const modalSize = isLargerThanMobile ? "lg" : "full";
  const { isOnline } = useConfigCheck();
  const { scrollbarRef, isScrollable, isAtEnd, handleScroll } = useScrollTracker();
  const { isOpen: isModalOpen, onOpen: openModal, onClose: closeModal } = useDisclosure();


  const [defaultValues, setDefaultValues] = useState(null) //set default values for modal
  const [isEditMode, setIsEditMode] = useState<boolean>(false)

  //get service label
  const services = useAppSelector(selectAllServices)
  const getService = (id: number) =>
    services.find((service: { id: number }) => service.id === id)

  //check whether entry is in charge already or not
  const isInCharges = (id: number) => {
    if (isdraft && dataresource && 'charges' in dataresource) {
      const name = getService(id)?.name
      return dataresource.charges?.some((item) => item.description === name)
    } else if (dataresource && 'chargedetails' in dataresource) {
      return dataresource.chargedetails.some((item) => item.record_id === id);
    }
    return false;
  };

  const handleModalClose = () => {
    setIsEditMode(false);
    setDefaultValues(null);
    closeModal();
  };

  //select resource based on draft or ticket
  const selectResource = isdraft ? useAppSelector(selectDraftServices(Number(resourceId))) :
    useAppSelector(selectTicketServices(Number(resourceId)))

  return (
    <Container>
      <PanelWrapper title="Additional Billable Items">
        {
          selectResource && selectResource?.length > 0 ?
            <Box
              width="100%"
              overflowX="auto"
              ref={scrollbarRef}
              onScroll={handleScroll}
              maxWidth="calc(100vw - 60px)"
              className={`custom-scrollbar  ${isScrollable && !isAtEnd ? 'blur' : ''}`}
            >
              <Table>
                <Thead color={"#455360"} >
                  <Tr sx={{ th: { borderBottom: "none", fontSize: { base: "12px", md: "1rem" }, fontWeight: 600, p: 2 } }}>
                    <Th minW={"200px"} >Name</Th>
                    <Th>Amount</Th>
                    <Th>Unit</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody color={"#7B8389"}>
                  {selectResource?.map((item, index) => (
                    <Tr key={index} h="50px" sx={{ td: { fontSize: { base: "12px", md: "1rem" }, p: 2 } }} _last={{ td: { borderBottom: "none" } }}>
                      <Td>{getService(item.service)?.name}</Td>
                      <Td>{item.quantity}</Td>
                      <Td>{getService(item.service)?.unit}</Td>
                      <Td textAlign="right">
                        {
                          !isInCharges(isdraft ? Number(item?.service) : Number(item?.record_id)) &&
                          <CustomButton title="$" w={"40px"}
                            isDisabled={(isOnline || isdraft) ? false : true}
                            onClick={() => {
                              openModal()
                              const { service, description, record_id, ...rest } = item
                              isInCharges(service)
                              const data = {
                                description: getService(item.service)?.name,
                                unit: getService(item.service)?.unit,
                                allow_surcharge: true,
                                section: "items",
                                record_id: item.record_id,
                                ...rest,
                              }
                              setDefaultValues(data as any)
                              setIsEditMode(true)
                            }}
                          />
                        }
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
            :
            <Box w="full" fontSize="14px" color="#455360" bgColor={"#fff"} borderRadius={"8px"} p={2}>
              There are no additional items
            </Box>
        }
        {/* Dialog for adding new charge entry */}
        <ModalForm
          isdraft={isdraft}
          title="Add New Entry"
          isOpen={isModalOpen}
          size={modalSize}
          onClose={handleModalClose}
          onCancel={handleModalClose}
          onSave={handlesavecharge}
          defaultValues={defaultValues}
        >
          <ChargesForm isEdit={isEditMode} />
        </ModalForm>
      </PanelWrapper>
    </Container >
  )
}
