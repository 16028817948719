import { parseISO } from "date-fns";
import DatePicker from "react-datepicker";
import { FieldProps } from "../../../forms";
import { useFormContext } from "react-hook-form";
import { KnownIcon } from "../../widgets/KnownIcon";
import { Box, FormControl, FormErrorMessage, FormHelperText, FormLabel, Input, useMediaQuery } from "@chakra-ui/react";


interface DatetimeFieldProps extends FieldProps {
    isRequired?: boolean
    error?: string | null
}

export function DatetimeField({
    name,
    label,
    error,
    helper,
    isRequired,
    registerOptions,
}: DatetimeFieldProps) {

    const [isSmallDevice] = useMediaQuery('(max-width: 480px)')
    const { watch, formState, setValue, register, trigger } = useFormContext()

    // Validate dateTime
    const validateTimeDate = (date: Date | null) => {
        if (!date) return isSmallDevice ? "Please select  date and time" : "Please enter a date in MM/DD/YYYY HH:mm AM/PM format"
        // if (date && date > currentDate) return "Time cannot be in the future"
        return undefined
    };

    // Default registration options
    const defaultRegisterOptions = {
        validate: { validateTimeDate },
    }

    // Merge provided registerOptions with default options
    const mergedRegisterOptions = {
        ...defaultRegisterOptions,
        ...registerOptions,
    }

    const handleChange = (date: any) => {
        setValue(name, date); // Update the form value with the selected date
        trigger(name)
    };

    return (
        <>
            <FormControl isInvalid={!!error || !!formState.errors?.[name]}>
                <FormLabel htmlFor={name}>{label} {isRequired && <span style={{ color: 'red' }}>*</span>}</FormLabel>
                <Box display="flex" alignItems="center" position="relative">
                    {/* Left Addon for Icon */}
                    <Box
                        p={3}
                        as="span"
                        bgColor="#ECF2F7"
                        _hover={{ bgColor: "#E2E8F0" }}
                        borderTopLeftRadius="md"
                        borderBottomLeftRadius="md"
                    >
                        <KnownIcon name="calendar" boxSize="24px" />
                    </Box>
                    {/* Date Picker Input */}
                    <Box flex="1">
                        <DatePicker
                            showTimeInput={isSmallDevice}  // Show time input on small devices
                            showTimeSelect={!isSmallDevice} // Show time select dropdown on larger devices
                            maxDate={new Date()}
                            timeIntervals={15}
                            selected={
                                // Ensure selected is always a Date object
                                watch(name) instanceof Date
                                    ? watch(name)
                                    : typeof watch(name) === 'string'
                                        ? parseISO(watch(name))
                                        : null
                            }
                            timeInputLabel="Time:"
                            shouldCloseOnSelect={isSmallDevice ? false : true}
                            dateFormat="MM/dd/yyyy h:mm aa"
                            placeholderText="MM/DD/YYYY HH:MM AM/PM"
                            onChange={handleChange}
                            customInput={
                                <Input
                                    id={name}
                                    {...register(name, mergedRegisterOptions)}
                                    h="48px"
                                    border="none"
                                    bgColor={"#EDF2F7"}
                                    borderTopLeftRadius={0}
                                    borderBottomLeftRadius={0}
                                    _hover={{ border: "none" }}
                                    _focus={{ outline: "none" }}
                                    inputMode="none"
                                    readOnly
                                />
                            }
                        />
                    </Box>
                </Box>
                <FormHelperText>{helper}</FormHelperText>
                <FormErrorMessage>
                    {error || formState.errors?.[name]?.message?.toString()}
                </FormErrorMessage>
            </FormControl>
        </>
    )
}