import { PuffLoader } from "react-spinners";
import { getDateString } from "../../app/utils";
import { useConfigCheck } from "../../app/hooks";
import { Box, Flex, Text } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { ErrorBox } from "../../components/ErrorBox";
import TimeEntryCard from "../../components/TimeEntryCard";
import { DetailHeading } from "../../components/DetailHeading";
import { KnownIcon } from "../../components/widgets/KnownIcon";
import { pageSizeOptions } from "../../features/tickets/ticket_choices";
import { FilterValues, OptionType } from "../../features/tickets/tickets";
import { PaginatedListLayout } from "../../layout/areas/PaginatedListLayout";
import { useDriverTimeEntryQuery } from "../../features/timeEntries/timeEntriesApi";

export type DateRange = [Date | null, Date | null];

export function SubmittedTimeEntries() {

  const { userProfile: { role } = {} } = useConfigCheck()
  const initialState = {
    dateRange: [null, null] as DateRange,
    currentPage: 1,
    itemsPerPage: pageSizeOptions[0],
  };
  const [filters, setFilters] = useState<FilterValues>(initialState);
  const [finalFilter, setFinalFilter] = useState<FilterValues>(filters);
  const [filterApplied, setFilterApplied] = useState(false);      //track state whether filter is applied or not
  const { dateRange, currentPage, itemsPerPage } = finalFilter;

  const url = useMemo(() => {
    const params = new URLSearchParams();
    if (dateRange[0]) params.append('date_range_after', getDateString(dateRange[0]));
    if (dateRange[1]) params.append('date_range_before', getDateString(dateRange[1]));
    if (currentPage) params.append('page', currentPage.toString());
    if (itemsPerPage) params.append('paginate', itemsPerPage?.value.toString());
    return params.toString();
  }, [finalFilter]);

  const { data, isFetching, isError, error, isSuccess } = useDriverTimeEntryQuery(url)

  const handleApplyFilter = () => {
    const hasDateRange = filters.dateRange[0] !== null;

    if (!filterApplied && hasDateRange) {
      setFilterApplied(true)
    } else {
      if (filterApplied && !hasDateRange) setFilterApplied(false)
    }
    setFinalFilter(filters)
    return hasDateRange  //return value to close popover in mobile view
  }

  //reset filter handler
  const handleResetFilter = () => {
    setFilters(initialState)
    setFinalFilter(initialState)
    setFilterApplied(false)
  }

  //page change handler
  const handlePageChange = (newPage?: number) => {
    setFilters(prev => ({ ...prev, currentPage: newPage || 1 }));
    setFinalFilter(prev => ({ ...prev, currentPage: newPage || 1 }))
  }
  //page size change handler
  const handlePageSizeChange = (size: OptionType) => {
    setFilters(prev => ({ ...prev, itemsPerPage: size }));
    setFinalFilter(prev => ({ ...prev, itemsPerPage: size }))
  }

  //set error if there is network issue
  const [networkError, setNetworkError] = useState<string>("");
  useEffect(() => {
    if (error && 'status' in error && error.status === 'FETCH_ERROR') {
      setNetworkError("You appear to have no or limited connectivity. This information will be visible once connected.");
      window.scrollTo({ top: 0, behavior: "smooth" })
    } else if (isSuccess && data) {
      setNetworkError("");
    }
  }, [isError, isSuccess, data]);


  const dailySummary = data?.timeentries?.daily_summary ?? [];
  const hasEntries = dailySummary.length > 0;


  return (
    !isError ?
      <Box w={{ base: "98%", sm: "95%", md: "90%" }} maxW={"1440px"} p={2} mx="auto">
        <PaginatedListLayout
          isLoading={isFetching}
          title={"My Time"}
          filters={filters}
          setFilters={setFilters}
          totalPage={data?.totalpage}
          filterApplied={filterApplied}
          handleApplyFilter={handleApplyFilter}
          handleResetFilter={handleResetFilter}
          handlePageChange={handlePageChange}
          handlePageSizeChange={handlePageSizeChange}
        >

          {
            !isFetching ?
              hasEntries && (role == "swamper" || role == "driver" || role == 'driver-lead' || role == "finance") ? (
                <>
                  <Flex width="full" justifyContent={'flex-end'} alignItems={"center"}>
                    <DetailHeading title='Total Hours : ' fontSize={16} />
                    <Flex minW={{ base: "120px", lg: "150px" }} p={1} gap={'10px'} fontSize={[12, 14]} borderRadius={"40px"} bgColor={'#EBEEFF'} justifyContent={"center"} alignItems={"center"}>
                      <KnownIcon name="clock" display={"inline"} boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                      <Text noOfLines={1} isTruncated>{`${Number(data?.timeentries?.grand_total_hours).toFixed(2)} Hours` || "--"}</Text>
                    </Flex>
                  </Flex>
                  {data?.timeentries?.daily_summary?.map((item: any, index: number) => (
                    <TimeEntryCard key={index} timeEntry={item} />
                  ))}
                </>)
                :
                <Box flex={1} alignContent={"center"}>No time entries found</Box>
              :
              <Flex w="full" justifyContent="center" alignItems="center" flex={1}>
                <PuffLoader
                  color="#3D82CE"
                  size={80}
                />
              </Flex>
          }
        </PaginatedListLayout>
      </Box>
      :
      <ErrorBox show={isError} error={error} message={networkError} />
  )
}
