import { Box } from "@chakra-ui/react";
import { ProductForm } from "./ProductForm";
import { forwardRef, useState } from "react";
import { Container } from "../../components/Container";
import { PanelWrapper } from "../../components/PanelWrapper";
import { OverlayLoader } from "../../components/OverlayLoader";
import { TicketResource } from "../../pages/user/TicketDetailPage";
import { WrapperProps } from "../../components/TicketDetailWrapper";
import { saveDraftProducts, selectDraftProducts } from "./draftSlice";
import { useAppDispatch, useAppSelector, useCustomToast } from "../../app/hooks";
import { removeProductDetail, selectTicketProducts, updateTicketProductDetails } from "../tickets/ticketsSlice";

interface ProductPanelProps extends WrapperProps {
    scrollTo?(data: string): void
    dataresource?: TicketResource
}

export const ProductsPanel = forwardRef<HTMLDivElement, ProductPanelProps>(
    function ProductsPanel({ resourceid, isdraft, dataresource }: ProductPanelProps, ref) {
        const dispatch = useAppDispatch()
        const { showToast } = useCustomToast();
        const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

        //Selector based on draft or ticket
        const selectResource = isdraft
            ? useAppSelector(selectDraftProducts(Number(resourceid)))
            : useAppSelector(selectTicketProducts(Number(resourceid))) || [];

        //Save handler based on draft or ticket
        const handleSaveResource = async (data: any) => {
            setIsSubmitting(true);
            try {
                if (isdraft) {
                    dispatch(saveDraftProducts({ id: Number(resourceid), updateDraftProducts: [...selectResource, data] }));
                } else {
                    await dispatch(updateTicketProductDetails({ id: resourceid, ...data })).unwrap();
                }
            } catch (error) {
                console.error("Action failed:", error);
                showToast({
                    status: 'error',
                    description: "Something went wrong",
                });
            } finally {
                setIsSubmitting(false);
            }
        };

        //Remove handlers based on draft or ticket
        const handleRemoveResource = async (index: number) => {
            if (isdraft) {
                const updateDraftProducts = selectResource.filter((_, i) => i !== index);
                dispatch(saveDraftProducts({ id: Number(resourceid), updateDraftProducts }));
            } else {
                setIsSubmitting(true)
                const equipmentId = dataresource?.productdetails?.[index]?.ticket_product_id;
                equipmentId && await dispatch(removeProductDetail({ id: Number(resourceid), ticket_product_id: Number(equipmentId) }));
                setIsSubmitting(false)
            }
        };

        return (
            <Box w="full" ref={ref}>
                {isSubmitting && <OverlayLoader />}
                <Container>
                    <PanelWrapper title="Label Information">
                        <ProductForm products={selectResource} onSave={handleSaveResource} onRemove={handleRemoveResource} isdraft={isdraft} />
                    </PanelWrapper>
                </Container>
            </Box >
        )
    })
