import { Container } from "../../Container";
import { useEffect, useState } from "react";
import { CustomButton } from "../CustomButton";
import { PanelWrapper } from "../../PanelWrapper";
import { KnownIcon } from "../../widgets/KnownIcon";
import { ImageWithSkeleton } from "./ImageWithSkeleton";
import { WrapperProps } from "../../TicketDetailWrapper";
import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import { TicketResource } from "../../../pages/user/TicketDetailPage";
import { saveDraftAttachments } from "../../../features/draft/draftSlice";
import { updateAttachments } from "../../../features/tickets/ticketsSlice";
import { useRemoveAttachmentMutation, useRetriveAttachmentsQuery } from "../../../features/aws/awsApi";
import { useAppDispatch, useConfigCheck, useCustomToast, useUploadFileToS3 } from "../../../app/hooks";

//filepond
import "filepond/dist/filepond.min.css";
import { FilePond, registerPlugin } from "react-filepond"
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

interface AttachmentDropZoneProps extends WrapperProps {
    dataresource?: TicketResource
}

export interface Attachment {
    id?: number | null;
    presigned_url: string | null;
    file_key?: string | null;
}

export function AttachmentDropZone({ dataresource, resourceid, isdraft }: AttachmentDropZoneProps) {
    const dispatch = useAppDispatch();
    const { isOnline } = useConfigCheck();
    const draftId = dataresource?.number;
    const { upload } = useUploadFileToS3();
    const { showToast } = useCustomToast();
    const [files, setFiles] = useState<File[]>([]);
    const [errorFiles, setErrorFiles] = useState<File[]>([]);
    const [isUploading, setIsUploading] = useState<boolean>(false);

    const [removeAttachment] = useRemoveAttachmentMutation();
    const { data: attachments, isLoading } = useRetriveAttachmentsQuery(draftId, { skip: !draftId });

    //attachment upload handler
    const handleUpload = async () => {
        if (!files.length) return;
        setIsUploading(true)
        try {
            draftId && await upload({ files, draftId, folderType: "attachments" })
            showToast({
                status: 'success',
                description: 'Attachments uploaded successfully',
            });
            setFiles([]);
        } catch (error) {
            console.error('Error uploading files:', error);
            showToast({
                status: 'error',
                description: 'Something went wrong , try after a while',
            });

        } finally {
            setIsUploading(false)
        }
    };
    //remove attachment handler
    const handleRemove = async (fileKey: string) => {
        if (draftId) {
            await removeAttachment({ draft_id: draftId, file_key: fileKey })
        };
    };

    //attachment save handler
    useEffect(() => {
        if (attachments && attachments?.length > 0) {
            const file_keys = attachments.map((item: Attachment) => (item.file_key))
            if (isdraft) {
                dispatch(saveDraftAttachments({ id: resourceid, attachments: [...file_keys] }))
            } else {
                dispatch(updateAttachments({ id: resourceid, attachments: [...file_keys] }))
            }
        }
    }, [attachments])


    //for filepond
    const FILE_LIMIT = import.meta.env?.VITE_FILEPOND_LIMIT;
    const MAX_FILE_SIZE = import.meta.env.VITE_MAX_FILE_SIZE;
    const ACCEPTED_FILE_TYPES = import.meta.env.VITE_FILEPOND_ACCEPTED_FILE_TYPES.split(',');

    const handleFileError = (error: any, file: any) => {
        if (error) setErrorFiles((prev) => [...prev, file?.file])
    }
    const handleRemoveFile = (error: any, file: any) => {
        setErrorFiles((prev) => prev.filter((f) => f !== file.file));
    }
    return (
        <Box w="full">
            <Container >
                <PanelWrapper title="Attachments">
                    <Flex gap={4} my={4} width="full" wrap="wrap">
                        {!isLoading && attachments && attachments?.length > 0 && attachments.map((attachment: Attachment) => (
                            <Box key={attachment.file_key} position="relative">
                                <ImageWithSkeleton
                                    key={attachment.file_key}
                                    attachment={attachment}
                                    handleRemove={handleRemove}
                                    showRemoveIcon={true}
                                />
                            </Box>))}
                    </Flex>
                    {(attachments?.length || 0) < FILE_LIMIT && (
                        <Container direction='column' w={{ base: "full", md: "90%" }} gap={4}>
                            <Box w="full" borderWidth="3px" borderRadius="md" p={4} borderColor="#3182CE" borderStyle="dashed" position="relative">
                                <FilePond
                                    files={files}
                                    allowMultiple
                                    disabled={!isOnline}
                                    allowFileSizeValidation
                                    allowFileTypeValidation
                                    onerror={handleFileError}
                                    maxFileSize={MAX_FILE_SIZE}
                                    onremovefile={handleRemoveFile}
                                    acceptedFileTypes={[...ACCEPTED_FILE_TYPES]}
                                    maxFiles={FILE_LIMIT - (attachments?.length || 0)}
                                    labelMaxFileSize={`Maximum file size is ${MAX_FILE_SIZE}`}
                                    onupdatefiles={(fileItems) => setFiles(fileItems.map((fileItem) => fileItem.file as File))}
                                />
                                {files.length > 0 && !errorFiles.length && (
                                    <CustomButton title='Upload' leftIcon={<KnownIcon name='upload' />} onClick={handleUpload} w={{ base: "90px", md: "100px" }} isDisabled={!isOnline} />
                                )}
                                {isUploading && (
                                    <Box
                                        top="0"
                                        left="0"
                                        width="100%"
                                        height="100%"
                                        display="flex"
                                        borderRadius="md"
                                        alignItems="center"
                                        position="absolute"
                                        justifyContent="center"
                                        backgroundColor="rgba(255, 255, 255, 0.8)"
                                    >
                                        <Spinner size="xl" color="blue.500" />
                                        <Text color="blue.500" fontSize="sm" mx={2}>Uploading...</Text>
                                    </Box>
                                )}
                            </Box>
                            <Text fontSize="small" color="#A0AEC0">
                                {`You can add maximum up to ${FILE_LIMIT - (attachments?.length || 0)} files, and each file size must be less than ${MAX_FILE_SIZE}`}.< br />
                                Supported file types are jpg, jpeg, png and pdf.
                            </Text>
                        </Container >)
                    }
                </PanelWrapper>
            </Container>
        </Box >
    )


}
