import { useMemo, useState } from "react";
import { ActivityTrait } from "./ActivityTrait";
import { ChargesForm } from "../charges/ChargesForm";
import { Container } from "../../components/Container";
import { PanelWrapper } from "../../components/PanelWrapper";
import { ModalForm } from "../../components/forms/ModalForm";
import { selectTicketTimeEntries } from "../tickets/ticketsSlice";
import { selectAllActivities } from "../activities/activitySlice";
import { TicketResource } from "../../pages/user/TicketDetailPage";
import { CustomButton } from "../../components/forms/CustomButton";
import { Draft, selectDraftTimeEntries } from "../draft/draftSlice";
import { ChargeWrapperProps } from "../../components/ChargeDetailWrapper";
import { useAppSelector, useConfigCheck, useScrollTracker } from "../../app/hooks";
import { formatMinutesAsHours, getGroupedDraftTimeEntry, getGroupedTicketTimeEntry } from "../../app/utils";
import { Box, Table, Tbody, Td, Text, Tfoot, Th, Thead, Tr, useDisclosure, useMediaQuery } from "@chakra-ui/react";

export interface TimeEntriesAggregatedProps extends ChargeWrapperProps {
  dataresource?: TicketResource | Draft | null
  handlesavecharge?(data: any): void
}

export function TimeEntriesAggregated({
  dataresource, resourceId, isdraft, handlesavecharge
}: TimeEntriesAggregatedProps) {

  const [isLargerThanMobile] = useMediaQuery("(min-width: 768px)");
  const modalSize = isLargerThanMobile ? "lg" : "full";

  const { isOnline } = useConfigCheck();
  const { scrollbarRef, isScrollable, isAtEnd, handleScroll } = useScrollTracker();
  const { isOpen: isModalOpen, onOpen: openModal, onClose: closeModal } = useDisclosure();

  //Selector based on draft or ticket
  const selectResource = isdraft ?
    useAppSelector(selectDraftTimeEntries(Number(resourceId)))
    : useAppSelector(selectTicketTimeEntries(Number(resourceId)))

  //set default value for modal
  const [defaultValues, setDefaultValues] = useState(null)
  const [isEditMode, setIsEditMode] = useState<boolean>(false)

  //check whether entry is in charge already or not
  const isInCharge = (activity: string | number) => {
    if (isdraft && dataresource && 'charges' in dataresource) {
      const act = getActivityLabel(Number(activity))
      return dataresource?.charges?.some((item) =>
        item.description === act
      )
    } else if (dataresource && 'chargedetails' in dataresource) {
      return dataresource.chargedetails.some((item) => item.description === activity);
    }
    return false;
  };

  //get activity label
  const Activities = useAppSelector(selectAllActivities)
  const getActivityLabel = (activity: number) => {
    return Activities.find((item) => item.id === activity)?.name
  }

  //group same activity and get grand total for individual activity
  const grouped: { [activity: string]: number } = useMemo(() => isdraft ? getGroupedDraftTimeEntry(selectResource) : getGroupedTicketTimeEntry(selectResource), [selectResource]);
  const grandTotal = grouped && Object?.values(grouped)?.reduce(
    (acc, totalHours) => acc + totalHours,
    0,
  )


  const handleModalClose = () => {
    setIsEditMode(false);
    setDefaultValues(null);
    closeModal();
  };

  return (
    <Container>
      <PanelWrapper title="Billable Time">
        {
          selectResource && selectResource?.length > 0 ?
            <Box
              overflowX="auto"
              ref={scrollbarRef}
              onScroll={handleScroll}
              maxWidth="calc(100vw - 60px)"
              className={`custom-scrollbar  ${isScrollable && !isAtEnd ? 'blur' : ''}`}
            >
              <Table variant="simple" >
                <Thead color={"#455360"} fontSize={"16px"} >
                  <Tr sx={{ th: { borderBottom: "none", fontSize: { base: "12px", md: "1rem" }, fontWeight: 600, p: 2 } }}>
                    <Th>Activity</Th>
                    <Th>Hours</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody color={"#7B8389"} fontSize={"16px"} >
                  {grouped && Object?.entries(grouped).map(([activity, totalHours]) => (
                    <Tr key={activity} h="50px" sx={{ td: { fontSize: { base: "12px", md: "1rem" }, p: 2 } }}>
                      <Td w="40%">
                        {isdraft ? <ActivityTrait choice={activity} /> : activity}
                      </Td>
                      <Td w="40%">{formatMinutesAsHours(totalHours)}</Td>
                      <Td w="20%" textAlign={"right"} borderBottom={"none"}>
                        {
                          !isInCharge(activity) &&
                          <>
                            <CustomButton title="$" w={"40px"}
                              isDisabled={(isOnline || isdraft) ? false : true}
                              onClick={() => {
                                const data = {
                                  description: isdraft ? getActivityLabel(Number(activity)) : activity,
                                  quantity: formatMinutesAsHours(totalHours),
                                  unit: "Hours",
                                  allow_surcharge: true,
                                }
                                setDefaultValues(data as any)
                                setIsEditMode(true)
                                openModal()
                              }}
                            />
                          </>

                        }
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
                <Tfoot color={"#455360"}>
                  {
                    grouped && Object.entries(grouped).length > 0 &&
                    <Tr color={"#455360"} >
                      <Th fontSize={{ base: "12px", md: "1rem" }} fontWeight={600} p={2} >
                        Total Hours:
                      </Th>
                      <Th fontSize={{ base: "12px", md: "1rem" }} fontWeight={600} p={2}>{formatMinutesAsHours(grandTotal)}</Th>
                      <Th></Th>
                    </Tr>
                  }
                </Tfoot>
              </Table >
            </Box >
            :
            <Box w="full" fontSize="14px" color="#455360" bgColor={"#fff"} borderRadius={"8px"} p={2}>
              There are no time entries
            </Box>
        }
        {/* Dialog for adding new charge entry */}
        <ModalForm
          isdraft={isdraft}
          title="Add New Entry"
          isOpen={isModalOpen}
          size={modalSize}
          onClose={handleModalClose}
          onCancel={handleModalClose}
          onSave={handlesavecharge}
          defaultValues={defaultValues}
        >
          <ChargesForm
            isEdit={isEditMode}
          />
        </ModalForm>
      </PanelWrapper >
    </Container >
  )
}
