import { useEffect, useState } from "react";
import ActivitySelect from "./ActivitySelect";
import { useLocation } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import WorkerSelect from "../workers/WorkerSelect";
import { DatetimeField } from "../../components/forms/fields/DatetimeField";
import { InputField } from "../../components/forms/fields/InputField";
import { TextareaField } from "../../components/forms/fields/TextareaField";

interface TimeEntryFormProps {
  isEdit?: boolean;
  isTicketPage?: boolean;
}

export function TimeEntryForm({
  isEdit = false,
  isTicketPage = true
}: TimeEntryFormProps) {
  const { watch } = useFormContext();
  //check whether it is draft page or not
  const pathname = useLocation().pathname
  const isTimeTrackingPage = pathname.includes("/tracking")

  const startTime = watch("start")
  const finishTime = watch("finish")

  const [finishTimeError, setFinishTimeError] = useState("");

  const validateTime = (start: string, finish: string) => {
    if (start > finish) {
      setFinishTimeError(
        "The finish time cannot be earlier than the start time.",
      )
    } else {
      setFinishTimeError("")
    }
  }

  useEffect(() => {
    if (startTime && finishTime) {
      validateTime(startTime, finishTime)
    }
  }, [startTime, finishTime])

  return (
    <>
      {
        isEdit &&
        <InputField
          name="id"
          hidden
        />
      }
      {(!isEdit && !!isTicketPage) && (
        <WorkerSelect
          label="Worker"
          name="worker"
          isRequired={true}
        />
      )}
      <ActivitySelect
        label="Activity"
        name="activity"
        // disabled={!!isEdit}
        isRequired={true}
      />
      <DatetimeField
        label="Start"
        name="start"
        isRequired={true}
      />
      <DatetimeField
        label="Finish"
        name="finish"
        error={finishTimeError}
        isRequired={true}
      />
      {isTimeTrackingPage && (
        <TextareaField
          label="Description"
          name="description"
          placeholder="Write description"
          maxLength={1000}
        />
      )}
    </>
  )
}