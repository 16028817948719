import * as yup from "yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { PuffLoader } from "react-spinners";
import { useCustomToast } from "../../app/hooks";
import { Stream } from "../../components/Stream";
import { formatDistance, parseISO } from "date-fns";
import { ErrorBox } from "../../components/ErrorBox";
import { yupResolver } from "@hookform/resolvers/yup";
import { baseValidationSchema, getRole } from '../../app/utils';
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import { Container } from "../../components/Container";
import { PanelWrapper } from "../../components/PanelWrapper";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { MainPageHeader } from "../../components/MainPageHeader";
import { BreadcrumbItemType } from "../../components/BreadCrumbs";
import { CustomButton } from "../../components/forms/CustomButton";
import { useChangePasswordMutation, useRetrieveProfileQuery } from "../../features/profile/profileApi"
import { Box, Flex, FormControl, FormErrorMessage, FormLabel, Icon, Input, InputGroup, InputRightElement, Stack, Text, VStack } from "@chakra-ui/react"
type Inputs = {
    current_password: string,
    new_password: string,
    confirm_new_password: string
}

const validationSchema = baseValidationSchema.shape({
    current_password: yup.string().required("please enter this field")
        .min(8, "Password must have at least 8 characters")
        .trim()
});

export function ProfilePage() {
    const { showToast } = useCustomToast();
    const { data, isLoading } = useRetrieveProfileQuery();
    const [showError, setShowError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [newPasswordShow, setNewPasswordShow] = useState<boolean>(false);
    const [confNewPasswordShow, setConfNewPasswordShow] = useState<boolean>(false);
    const [changePassword] = useChangePasswordMutation();

    const created =
        data !== undefined
            ? formatDistance(parseISO(data?.created), new Date(), {
                addSuffix: true,
            })
            : null

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isSubmitting },
    } = useForm<Inputs>({
        resolver: yupResolver(validationSchema)
    })

    const handlePasswordChange = async (data: Inputs) => {
        try {
            const response = await changePassword(data).unwrap();
            showToast({
                status: 'success',
                description: "Your password has been reset successfully.",
                isClosable: true,
                duration: 5000, // 5 sec duration
            })
            console.log('Password change successful:', response);
            reset()
        } catch (err) {
            console.error('Password change failed:', err);
            const error = err as FetchBaseQueryError;
            const errString = Object.entries(error?.data as object).map(([key, value]) => {
                return value.message
            }).join()
            setShowError(true)
            setErrorMessage(errString)
        }
    }

    const breadcrumbItems: BreadcrumbItemType[] = [
        { label: 'Home', path: '/user/home', icon: 'home' },
        { label: 'Profile', isCurrentPage: true },
    ];
    return (
        <>
            {!isLoading ?
                <>
                    <MainPageHeader breadCrumbItems={breadcrumbItems} />
                    <Container direction="column">
                        <Stream title="User Information">
                            <Stack w="full" flexDirection={{ base: "column", md: "row" }} gap={"2rem"} wrap={"wrap"}>
                                <Box w={"full"} maxW={"440px"}>
                                    <Text fontWeight={500} color={"#455360"}>Username</Text>
                                    <Input defaultValue={data?.user.username} color={"#7B8389"} readOnly />
                                </Box>
                                <Box w={"full"} maxW={"440px"}>
                                    <Text fontWeight={500} color={"#455360"}>Role</Text>
                                    <Input defaultValue={getRole(data?.role || "")} color={"#7B8389"} readOnly />
                                </Box>
                            </Stack>
                            <Box>
                                <Text color={"#455360"} fontSize={"14px"} fontWeight={500}>Created <Text as={"span"} color={"#7B8389"} fontSize={"14px"} fontWeight={400}> {created} </Text> </Text>
                            </Box>
                        </Stream>

                        <PanelWrapper title="Reset Password">
                            <Box bg="white" rounded="4px" w="full">
                                <form id="change_password" onSubmit={handleSubmit(handlePasswordChange)} >
                                    <ErrorBox show={showError} message={errorMessage} />
                                    <VStack align="start" width="full" gap={3} p={5}>
                                        <FormControl isInvalid={!!errors.current_password}>
                                            <FormLabel size="sm" fontWeight={500} color={"#455360"} >Current Password <span style={{ color: 'red' }}>*</span></FormLabel>
                                            <Input
                                                {...register("current_password")}
                                                type='password'
                                                placeholder='Enter Current Password'
                                                color={"#7B8389"}
                                                variant="filled"
                                                width={{ base: "100%", md: "440px" }}
                                            />
                                            <FormErrorMessage>{errors.current_password?.message}</FormErrorMessage>
                                        </FormControl>
                                        <Stack flexDirection={{ base: "column", md: "row" }} align="left" columnGap={{ base: 3, md: 8 }} rowGap={3} w="full" wrap={"wrap"} alignItems={"center"}>
                                            <FormControl isInvalid={!!errors.new_password} width={{ base: "100%", md: "440px" }} >
                                                <FormLabel size="sm" fontWeight={500} color={"#455360"} >New Password <span style={{ color: 'red' }}>*</span></FormLabel>
                                                <InputGroup >
                                                    <Input
                                                        {...register("new_password")}
                                                        type={newPasswordShow ? 'text' : 'password'}
                                                        placeholder='Enter New Password'
                                                        color={"#7B8389"}
                                                        variant="filled"
                                                    />
                                                    <InputRightElement width='4.5rem'>
                                                        {
                                                            newPasswordShow ?
                                                                <Icon as={VscEyeClosed} onClick={() => setNewPasswordShow(!newPasswordShow)} boxSize={6} cursor="pointer" />
                                                                :
                                                                <Icon as={VscEye} onClick={() => setNewPasswordShow(!newPasswordShow)} boxSize={6} cursor="pointer" />
                                                        }
                                                    </InputRightElement>
                                                </InputGroup>

                                                <FormErrorMessage>{errors.new_password?.message}</FormErrorMessage>
                                            </FormControl>
                                            <FormControl isInvalid={!!errors.confirm_new_password} width={{ base: "100%", md: "440px" }} >
                                                <FormLabel size="sm" fontWeight={500} color={"#455360"}>Confirm New Password <span style={{ color: 'red' }}>*</span></FormLabel>
                                                <InputGroup >
                                                    <Input
                                                        {...register("confirm_new_password")}
                                                        type={confNewPasswordShow ? 'text' : 'password'}
                                                        placeholder='Enter Confirm New Password'
                                                        color={"#7B8389"}
                                                        variant="filled"
                                                    />
                                                    <InputRightElement width='4.5rem'>
                                                        {
                                                            confNewPasswordShow ?
                                                                <Icon as={VscEyeClosed} onClick={() => setConfNewPasswordShow(!confNewPasswordShow)} boxSize={6} cursor="pointer" />
                                                                :
                                                                <Icon as={VscEye} onClick={() => setConfNewPasswordShow(!confNewPasswordShow)} boxSize={6} cursor="pointer" />
                                                        }
                                                    </InputRightElement>
                                                </InputGroup>
                                                <FormErrorMessage>{errors.confirm_new_password?.message}</FormErrorMessage>
                                            </FormControl>
                                            <CustomButton
                                                mt="auto"
                                                title="Submit"
                                                type="submit"
                                                form="change_password"
                                                isLoading={isSubmitting}
                                            />
                                        </Stack>
                                    </VStack>
                                </form>
                            </Box>
                        </PanelWrapper>
                    </Container>
                </>
                :
                <Flex w="full" justifyContent="center" alignItems="center" flex={1}>
                    <PuffLoader
                        color="#3D82CE"
                        size={80}
                    />
                </Flex>
            }
        </>
    )
}
