import { ModalForm } from './forms/ModalForm';
import { useConfigCheck } from '../app/hooks';
import { KnownIcon } from './widgets/KnownIcon';
import { ChangeEvent, Fragment, useState } from 'react';
import { TimeEntryForm } from '../features/timeEntries/TimeEntryForm';
import { TimeEntriesResource } from '../pages/user/ApproveSubmittedTimeEntries';
import { formatMinutesAsHours, formatTimeOnly, getDateString } from '../app/utils';
import { usePartialUpdateTimeEntryMutation } from '../features/timeEntries/timeEntriesApi';
import { Box, Card, CardBody, Flex, HStack, IconButton, Spacer, Stack, StackDivider, Switch, Text, VStack } from '@chakra-ui/react';

interface ApproveTimeEntryCardProps {
    timeEntry: TimeEntriesResource
}

export function ApproveTimeEntryCard({ timeEntry }: ApproveTimeEntryCardProps) {
    const { isOnline } = useConfigCheck();
    const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);

    const [partialUpdateTimeEntry] = usePartialUpdateTimeEntryMutation();

    const handleApproveTime = async (e: ChangeEvent<HTMLInputElement>, id: number) => {
        const { checked } = e.target
        partialUpdateTimeEntry({ id, is_approved: checked })
    }

    const handleEditTimeEntry = () => {
        setIsEditModalOpen(true);
    }

    const handleCloseEditModal = () => {
        setIsEditModalOpen(false);
    }

    const handleSaveTimeEntry = async (data: any) => {
        try {
            await partialUpdateTimeEntry({
                id: timeEntry.id,
                ...data
            });
            handleCloseEditModal(); // close the model
        } catch (error) {
            console.error('Failed to update time entry', error);
        }
    }

    const defaultValues = {
        id: timeEntry.id,
        activity: timeEntry.activity.id,
        start: timeEntry.start,
        finish: timeEntry.finish,
    };

    return (
        <Fragment >
            <Card w="full" >
                <CardBody p={{ base: 2, lg: 5 }} color={"#0E1628"}>
                    <Stack p={2} divider={<StackDivider />} spacing='4' color={"#0E1628"}>
                        <HStack alignItems={"center"} justifyContent={"space-between"} >
                            <Flex
                                flex={1}
                                wrap="wrap"
                                rowGap={2}
                                columnGap={20}
                                alignItems="center"
                            >
                                {
                                    typeof timeEntry?.ticket === "number" &&
                                    <Box>
                                        <Text
                                            minW={{ base: "auto", sm: "30%" }}
                                            fontSize={{ base: "12px", sm: "16px" }}
                                            fontWeight={600}
                                        >
                                            {`#${timeEntry.ticket}`}
                                        </Text>
                                    </Box>
                                }
                                <VStack alignItems={"flex-start"} gap={1}>
                                    {
                                        timeEntry?.driver &&
                                        <Flex gap={{ base: "6px", lg: 3 }} alignItems={"center"}>
                                            <KnownIcon name="driver" boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                            <Text
                                                whiteSpace="nowrap"
                                                overflow="hidden"
                                                maxWidth={{ base: "160px", sm: "250px", md: "250px", lg: "350px" }}
                                                fontSize={{ base: "14px", sm: "16px", lg: "18px" }} fontWeight={600}
                                                isTruncated
                                            >
                                                {timeEntry?.driver}
                                            </Text>
                                        </Flex>
                                    }
                                    {
                                        timeEntry?.worker &&
                                        <Flex gap={{ base: "6px", lg: 3 }} alignItems={"center"}>
                                            <KnownIcon name="worker" boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                            <Text
                                                whiteSpace="nowrap"
                                                overflow="hidden"
                                                maxWidth={{ base: "160px", sm: "250px", md: "250px", lg: "350px" }}
                                                fontSize={{ base: "14px", sm: "16px", lg: "18px" }} fontWeight={600}
                                                isTruncated
                                            >
                                                {timeEntry?.worker}
                                            </Text>
                                        </Flex>
                                    }
                                </VStack>
                            </Flex>
                            <IconButton
                                aria-label="edit"
                                icon={<KnownIcon name="edit" boxSize={{ base: "16px", md: "20px", lg: "24px" }} cursor="pointer" />}
                                onClick={handleEditTimeEntry}
                                backgroundColor="transparent"
                                _hover={{ backgroundColor: "transparent" }}
                            />
                            <Flex fontSize={{ base: "12px", sm: "16px" }} color={"#455360"} gap={[2, 3, 5]} alignItems="center">
                                Approve:
                                <Switch
                                    sx={{
                                        '.chakra-switch__track': {
                                            bg: 'gray.300', // background when off
                                        },
                                        '.chakra-switch__track[data-checked]': {
                                            bg: '#3455FF', // custom background color when on
                                        },
                                    }}
                                    isChecked={timeEntry.is_approved}
                                    onChange={(e) => handleApproveTime(e, timeEntry.id)} display="inline"
                                    isDisabled={!isOnline}
                                    size={{ base: "sm", md: "md" }}
                                />
                            </Flex>
                        </HStack>
                        <HStack w="full" gap={5} >
                            <Flex
                                alignItems="center"
                                columnGap={4}
                                rowGap={2}
                                wrap="wrap"
                                justifyContent="space-between"
                                flex="1"
                            >
                                <VStack alignItems={"flex-start"} >
                                    <Flex gap={{ base: "6px", lg: 3 }} alignItems={"center"}>
                                        <KnownIcon name="customer" boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                        <Text
                                            whiteSpace="nowrap"
                                            overflow="hidden"
                                            maxWidth={{ base: "160px", sm: "250px", md: "250px", lg: "350px" }}
                                            fontSize={{ base: "14px", sm: "16px", lg: "18px" }} fontWeight={500}
                                            isTruncated
                                        >
                                            {timeEntry?.customer}
                                        </Text>
                                    </Flex>
                                    <Flex gap={{ base: "6px", lg: 3 }} alignItems={"center"}>
                                        <KnownIcon name="activity" boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                        <Text fontSize={"14px"} fontWeight={400}> {timeEntry.activity.name}</Text>
                                    </Flex>
                                </VStack>
                                <Flex gap={5} fontSize={{ base: "12px", lg: "16px" }} >
                                    <Flex gap={{ base: "6px", lg: 3 }} alignItems={"center"}>
                                        <KnownIcon name="clock" boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                        <Text>{`${formatMinutesAsHours(timeEntry?.minutes)} Hours`}</Text>
                                    </Flex>
                                    <Flex gap={{ base: "6px", lg: 3 }} alignItems={"center"}>
                                        <KnownIcon name="calendar" boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                        <Text>{`${getDateString(timeEntry?.finish ?? '')}`}</Text>
                                    </Flex>
                                </Flex>
                            </Flex>

                        </HStack>
                        {/* </VStack> */}

                        <HStack>
                            <Box w="fit-content" minW={"100px"}>
                                <Text color={"#666E82"} fontSize={{ base: 12, md: 14 }}>Start Time</Text>
                                <Flex gap={{ base: "6px", lg: 3 }} alignItems={"center"}>
                                    <KnownIcon name="clock" boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                    <Text fontSize={{ base: "12px", sm: "14px", lg: "16px" }} >{formatTimeOnly(timeEntry?.start) || "--"} </Text>
                                </Flex>
                            </Box>
                            <Spacer borderBottom={"2px dashed #9F9F9F"} />
                            <Box w="fit-content" minW={"100px"}>
                                <Text color={"#666E82"} fontSize={{ base: 12, md: 14 }}>End Time</Text>
                                <Flex gap={{ base: "6px", lg: 3 }} alignItems={"center"}>
                                    <KnownIcon name="clock" boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                    <Text fontSize={{ base: "12px", sm: "14px", lg: "16px" }}  >{formatTimeOnly(timeEntry?.finish) || "--"} </Text>
                                </Flex>
                            </Box>
                        </HStack>
                    </Stack>
                </CardBody>
            </Card>
            <ModalForm
                isdraft={false}
                title={`Edit Time Entry`}
                isOpen={isEditModalOpen}
                size={["full", "lg"]}
                defaultValues={defaultValues}
                onClose={handleCloseEditModal}
                onCancel={handleCloseEditModal}
                onSave={handleSaveTimeEntry}
            >
                <TimeEntryForm isTicketPage={false} />
            </ModalForm>
        </Fragment >
    )
}
