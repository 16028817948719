import React, { useMemo } from "react";
import { FieldProps } from "../../forms";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { selectAllActivities } from "../activities/activitySlice";
import { SelectField } from "../../components/forms/fields/SelectField";
import { selectAllUsers } from "../users/usersSlice";
import { useListUsersQuery } from "../users/usersApi";

interface WorkerSelectProps extends FieldProps {
  isRequired?: boolean
  id?: number
}

const WorkerSelect: React.FC<WorkerSelectProps> = (
  props: WorkerSelectProps,
) => {
  const { isLoading, isError } = useListUsersQuery();

  const allUsers = useAppSelector(selectAllUsers)

// Check if users are already loaded in the Redux store, and if not, trigger the query.
if (allUsers.length < 1 && !isLoading && !isError) {
  // Only call the query if there are no users and the query isn't already running or in error state
  useListUsersQuery();
}
  const options = useMemo(() => {
    return allUsers
    .filter((item) => !!(item.first_name?.trim() || item.last_name?.trim()))
    .map((item) => ({ value: item.id, label: `${item.first_name} ${item.last_name}` }));
  }, [allUsers]);


  return <SelectField {...props} options={options} isRequired={props.isRequired} />
}

export default WorkerSelect
