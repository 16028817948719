import {
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  InputGroup,
  Textarea,
  Text,
} from "@chakra-ui/react"

import { RegisterOptions, useFormContext } from "react-hook-form"
import { useMemo } from "react";

interface TextareaFieldProps {
  label: string
  name: string
  registerOptions?: RegisterOptions
  helper?: string
  placeholder?: string
  maxLength?: number
}

export function TextareaField({
  label,
  name,
  registerOptions,
  helper,
  placeholder,
  maxLength = 200
}: TextareaFieldProps) {
  const { register, watch, formState: { errors } } = useFormContext()
  const value = watch(name)
  const charLength = value?.length || 0

  const isRequired = useMemo(() => {
    return registerOptions?.required !== undefined;
  }, [registerOptions]);

  return (
    <FormControl isInvalid={!!errors.reason}>
      <FormLabel htmlFor={name} color="#455360">
        {label} {isRequired && <span style={{ color: 'red' }}>*</span>}
      </FormLabel>
      <InputGroup>
        <Textarea
          id={label}
          {...register(name, registerOptions)}
          placeholder={placeholder}
          maxLength={maxLength}
          variant="filled"
          color={"#0E1628"}
        />
      </InputGroup>
      <FormHelperText display="flex" justifyContent="space-between">
        <Text>
          {helper}
        </Text>
        <Text display={"inline-block"} color={charLength === maxLength ? "red.500" : "grey.600"}>{charLength}/{maxLength}</Text>
      </FormHelperText>
      <FormErrorMessage>{`${errors.reason?.message}` || "This field is required1"}</FormErrorMessage>
    </FormControl>
  )
}
