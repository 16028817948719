import DatePicker from 'react-datepicker';
import { FieldProps } from '../../../forms';
import { useFormContext } from "react-hook-form";
import { formatDateOnly } from "../../../app/utils";
import { KnownIcon } from "../../widgets/KnownIcon";
import { isValid, isDate, parseISO } from "date-fns";
import { FormControl, FormLabel, FormHelperText, FormErrorMessage, Input, VStack, Box } from "@chakra-ui/react";

interface DateFieldProps extends FieldProps { }

export function DateField({
  label,
  name,
  helper,
  registerOptions,
}: DateFieldProps) {
  const { register, formState, setValue, watch, } = useFormContext()

  const transformInput = (value: any) => {
    if (isDate(value) && isValid(value)) {
      return formatDateOnly(value); // Format the date to MM/DD/YYYY format
    } else if (typeof value === 'string' && value.trim() !== '') {
      // If the value is a string (e.g., initial UTC string), parse and format it
      const parsedValue = parseISO(value);
      if (isDate(parsedValue) && isValid(parsedValue)) {
        return formatDateOnly(parsedValue); // Format the parsed date to MM/DD/YYYY format
      }
    }
    return value;
  }

  return (
    <FormControl isInvalid={!!formState.errors?.[name]} >
      <VStack gap={"10px"} alignItems={"flex-start"}>
        <FormLabel color="#455360">{label}</FormLabel>
        <Box w="full" display="flex" alignItems="center" position="relative">
          <Box
            p={3}
            as="span"
            bgColor="#ECF2F7"
            _hover={{ bgColor: "#E2E8F0" }}
            borderTopLeftRadius="md"
            borderBottomLeftRadius="md"
          >
            <KnownIcon name="calendar" boxSize="24px" />
          </Box>

          <Box flex="1">
            <DatePicker
              selected={watch(name)}
              onChange={(date) => setValue(name, date)}
              dateFormat="MM/dd/yyyy "
              placeholderText="MM/DD/YYYY"
              shouldCloseOnSelect={true}
              popperClassName="react-datepicker-popper"
              maxDate={new Date()}
              customInput={
                <Input
                  id={name}
                  {...register(name, registerOptions)}
                  value={transformInput(watch(name) || "")}
                  h="48px"
                  border="none"
                  bgColor={"#EDF2F7"}
                  borderTopLeftRadius={0}
                  borderBottomLeftRadius={0}
                  _focus={{ outline: "none", backgroundColor: "transparent", borderColor: "#3D82CE", boxShadow: "0 0 0 2px #3182ce" }}
                  _hover={{ border: "none" }}
                />
              }
            />
          </Box>
        </Box>
        {helper && <FormHelperText>{helper}</FormHelperText>}
        <FormErrorMessage>
          {formState.errors?.[name]?.message?.toString()}
        </FormErrorMessage>
      </VStack>
    </FormControl>
  )
}
