import { useState } from "react";
import { Link } from "react-router-dom";
import { loginUser } from "./sessionSlice";
import { useMessageBox } from "../../app/hooks";
import { useAppDispatch } from "../../app/hooks";
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import { MessageBox } from "../../components/MessageBox";
import { REQUIRED_FIELDS } from "../draft/RequiredFields";
import { KnownIcon } from "../../components/widgets/KnownIcon";
import { CustomButton } from "../../components/forms/CustomButton";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { FormControl, FormLabel, FormErrorMessage, Input, VStack, Box, Flex, InputGroup, InputRightElement, Icon } from "@chakra-ui/react"
type Inputs = {
    username: string
    password: string
}
export function SessionLogin() {
    const dispatch = useAppDispatch()
    // Default form values
    const defaultValues = {
        username: '',
        password: '',
    };
    const {
        handleSubmit,
        reset,
        formState: { errors, isSubmitting },
        control,
    } = useForm<Inputs>({ defaultValues })
    const [passwordShow, setPasswordShow] = useState<boolean>(false)
    const { showAlert, alertStatus, alertMessage, errorDetail, setAlert } = useMessageBox();
    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        try {
            const result = await dispatch(loginUser(data) as any)

            let errorString = ''
            if (result?.error) {
                const errors = result?.payload.data
                if (Object.entries(errors).length > 1) {
                    const err = Object.entries(errors).map(([key, value]) => {
                        const text = REQUIRED_FIELDS.find(
                            (option) => option.value === key,
                        )?.label
                        return text
                    })
                    errorString = `${err.join(" and ")} are required`
                } else {
                    switch (Object.entries(errors)[0][0]) {
                        case "password":
                            errorString = errors['password'][0];
                            break;
                        case "username":
                            errorString = errors['username'][0];
                            break;
                        case "msg":
                            errorString = errors['msg'][0];
                            break
                    }
                }
                setAlert('error', errorString)
            } else {
                console.info("Logged in user", result?.payload?.profile)
            }
        } catch (error) {
            console.error("Login failed for user", error)
        }
        finally {
            reset()
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} >
                <VStack align="start" width="full" gap={5} color="#455360" >
                    <MessageBox
                        show={showAlert}
                        status={alertStatus}
                        message={alertMessage}
                        error={errorDetail}
                    />
                    <FormControl isInvalid={!!errors.username}>
                        <FormLabel>User Name</FormLabel>
                        <Controller
                            control={control}
                            name="username"
                            rules={{ required: 'A username is required' }}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    type="text"
                                    variant='filled'
                                    placeholder="Type your user name"
                                    onChange={(e) => field.onChange(e.target.value.trim().length > 0 ? e.target.value : '')} // Trim value on change
                                />
                            )}
                        />
                        <FormErrorMessage>{errors.username?.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors.password}>
                        <FormLabel>Password</FormLabel>
                        <Controller
                            control={control}
                            name="password"
                            rules={{ required: 'Password is missing' }}
                            render={({ field }) => (
                                <InputGroup>
                                    <Input
                                        {...field}
                                        type={passwordShow ? 'text' : 'password'}
                                        variant='filled'
                                        placeholder="Type your password"
                                        onChange={(e) => field.onChange(e.target.value.trim())} // Trim value on change
                                    />
                                    <InputRightElement width='4.5rem'>
                                        {
                                            passwordShow ?
                                                <Icon as={VscEyeClosed} onClick={() => setPasswordShow(!passwordShow)} boxSize={6} cursor="pointer" />
                                                :
                                                <Icon as={VscEye} onClick={() => setPasswordShow(!passwordShow)} boxSize={6} cursor="pointer" />
                                        }
                                    </InputRightElement>
                                </InputGroup>

                            )}
                        />
                        <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
                    </FormControl>
                    <CustomButton type="submit" title="Log In" w="full" isLoading={isSubmitting} />
                    <Box w="full">
                        <Flex
                            alignItems="center"
                            justifyContent="center"
                            mx="auto"
                        >
                            <KnownIcon name="lock" mr={2} />
                            <Link to="/auth/forgot-password">
                                Forgot your password?
                            </Link>
                        </Flex>
                    </Box>

                </VStack>
            </form>
        </>
    )
}
