import { useEffect, useState } from "react";
import { PuffLoader } from "react-spinners";
import { useParams } from "react-router-dom";
import { Stream } from "../../components/Stream";
import { ErrorBox } from "../../components/ErrorBox";
import { PrintContent } from "../print/TicketDetailPrint";
import { StampForm } from "../../features/tickets/StampForm";
import { ModalForm } from "../../components/forms/ModalForm";
import { OverlayLoader } from "../../components/OverlayLoader";
import { BreadcrumbItemType } from "../../components/BreadCrumbs";
import { CustomButton } from "../../components/forms/CustomButton";
import { StampTicketHeader } from "../../components/StampTicketHeader";
import { Attachment } from "../../components/forms/fields/AttachmentDropZone";
import { ImageWithSkeleton } from "../../components/forms/fields/ImageWithSkeleton";
import { useCustomToast, useConfigCheck, useUploadFileToS3 } from "../../app/hooks";
import { Box, ButtonGroup, Flex, Text, useMediaQuery, VStack } from "@chakra-ui/react";
import { useRetriveAttachmentsQuery, useRetriveSignatureQuery } from "../../features/aws/awsApi";
import { usePartialUpdateTicketMutation, useRetrieveTicketQuery } from "../../features/tickets/ticketsApi";
import { RejectTicketForm } from "../../features/tickets/RejectTicketForm";
interface PrintContentProps {

}
export enum Mode {
    CodeOnly = 'code_only',
    CodeAndStamp = 'code_and_stamp',
    Reject = 'reject',
    Default = 'default',
}
export function StampTicketDetail({ }: PrintContentProps) {
    const { id } = useParams();
    const { isOnline, userProfile } = useConfigCheck();
    const { showToast } = useCustomToast();
    const { upload } = useUploadFileToS3();
    const [isOpen, setIsOpen] = useState(false);


    const [mode, setMode] = useState<Mode>(Mode.CodeOnly);
    // const { isOpen, onClose, onOpen } = useDisclosure();
    const [isFormSubmit, setIsFormSubmit] = useState<boolean>(false);
    const [isBigScreen] = useMediaQuery("(min-width: 992px)");

    const [partialUpdateTicket] = usePartialUpdateTicketMutation();

    //retrive ticket
    const { data: ticket, isLoading, isError, error } = useRetrieveTicketQuery(Number(id));
    const ticketNumber = ticket?.number;
    const ticketStatus = ticket?.status || "";
    const isDigitalSigned = ticket?.digital_signed || false;
    const shouldSkipAttachmentsQuery = isLoading || isError || !ticketNumber;

    const { data: attachments, isLoading: isAttachmentLoading } = useRetriveAttachmentsQuery(ticketNumber, { skip: shouldSkipAttachmentsQuery });
    const { data: signatureData, isLoading: isSignatureDataLoading } = useRetriveSignatureQuery(ticketNumber, { skip: !(ticketNumber && ticket.digital_signed) });

    const defaultValues = {
        cost_class: ticket?.cost_class || null,
        minor_class: ticket?.minor_class || null,
        major_class: ticket?.major_class || null,
        other: ticket?.other || null,
        billingCustomer: ticket?.customer_billing?.contact_id || ticket?.customer.contacts.find(contact => contact.id === userProfile?.id)?.contact_id || null,
        digital_sign: null
    }

    // Modify onOpen to set both open state and mode
    const onOpen = (currentMode: Mode) => {
        setMode(currentMode);
        setIsOpen(true);
    };

    const handelCancelTicket = async (data: any) => {
        setIsFormSubmit(true);
        try {
            const payload = {
                id: Number(id),
                status: "customer_rejected",
                customer_reject_reason: data.reason
            };

            const updateResponse = await partialUpdateTicket(payload);

            if (!("data" in updateResponse)) {
                throw new Error(`Failed to cancel ticket: ${JSON.stringify(updateResponse)}`);
            }

            showToast({
                status: "success",
                description: "Ticket has been successfully rejected",
            });

            setIsOpen(false);
        } catch (error) {
            console.error("Error during ticket cancellation:", error);
            showToast({
                status: "error",
                description: "Failed to cancel ticket. Please try again.",
            });
        } finally {
            setIsFormSubmit(false);
        }
    };

    const handelStampDetails = async (data: any) => {
        setIsFormSubmit(true);

        // Destructure data with default values
        const {
            digital_sign,
            other,
            minor_class,
            major_class,
            cost_class,
            billingCustomer
        } = data;

        try {
            let key = null;
            let payload: any = {
                id: Number(id),
                cost_class,
                minor_class,
                major_class,
                other,
                customer_billing_id: billingCustomer
            };

            // Handle different modes
            if (mode === Mode.CodeAndStamp) {
                // Upload signature for code and stamp mode
                key = ticketNumber && await upload({
                    files: digital_sign,
                    folderType: "signature",
                    draftId: ticketNumber
                });

                if (!key) throw new Error("Failed to upload file in s3");

                // Add additional fields for code and stamp mode
                payload = {
                    ...payload,
                    digital_signed: true,
                    signature: key,
                    status: "stamped",
                };
            } else {
                // Code only mode
                payload = {
                    ...payload,
                    digital_signed: false,
                    customer_coded_id: userProfile?.customer_contact_id || null,
                    status: "coded",
                };
            }

            // Update ticket details
            const updateResponse = await partialUpdateTicket(payload);

            if (!("data" in updateResponse)) {
                throw new Error(`Failed to update ticket details: ${JSON.stringify(updateResponse)}`);
            }

            // Show success toast message
            showToast({
                status: "success",
                description: mode === 'code_and_stamp'
                    ? "You have successfully authorized the ticket"
                    : "You have successfully coded the ticket",
            });
        } catch (error) {
            console.error("Error during ticket update process:", error);
            showToast({
                status: "error",
                description: "An error occurred, Please try again",
            });
        } finally {
            setIsFormSubmit(false);
        }
    };

    //set error if there is network issue
    const [networkError, setNetworkError] = useState("");
    useEffect(() => {
        if (error && 'status' in error && error.status === 'FETCH_ERROR') {
            setNetworkError("Ticket details are not available without network connectivity");
            window.scrollTo({ top: 0, behavior: "smooth" })
        }
    }, [isError])

    const breadcrumbItems: BreadcrumbItemType[] = [
        { label: 'All Tickets', path: '/user/tickets', icon: 'home' },
        { label: `#${ticketNumber || ''}`, isCurrentPage: true }, // No path since it's the current page
    ];

    const handleFormSubmit = async (data: any) => {
        if (mode === Mode.Reject) {
            await handelCancelTicket(data);
        } else {
            await handelStampDetails(data);
        }
    };

    return (
        <Box w="full">
            <StampTicketHeader
                isLoading={isLoading}
                onOpen={onOpen}
                ticketStatus={ticketStatus}
                isDigitalSigned={isDigitalSigned}
                breadCrumbItems={breadcrumbItems}
            />
            <VStack w={"90%"} p={2} gap={4} alignItems={"flex-start"} mx="auto" >
                <ErrorBox show={isError ? true : false} error={error} message={networkError} />
                {
                    !isError &&
                    <>
                        < Box w={"100%"} overflowX={"auto"} maxW={"calc(100vw - 50px)"} border="2px solid #bee3f8" borderRadius={"5px"} bgColor={"#fff"}>
                            {
                                (!isLoading && !isSignatureDataLoading && ticket) ?
                                    <Box w="90%" minW="750px" minH={"750px"} my={10} mx={"auto"}>
                                        <PrintContent ticket={ticket} charges={ticket?.chargedetails || []} signatureData={signatureData?.[0]} />
                                    </Box>
                                    :
                                    <Flex w="full" minH={"750px"} alignItems="center" justifyContent="center">
                                        <PuffLoader
                                            color="#3D82CE"
                                            size={80}
                                        />
                                    </Flex>
                            }
                        </Box>
                        <VStack w="full" spacing={4} p={5} alignItems="flex-start">
                            <Stream title="Attachments" color={"#05004E"}>
                                <Flex gap={4} my={4} wrap="wrap" w="full">
                                    {
                                        !isAttachmentLoading ?
                                            attachments && attachments?.length > 0 ? attachments?.map((attachment: Attachment) => (
                                                <Box position="relative" key={attachment.file_key}>
                                                    <ImageWithSkeleton
                                                        key={attachment.file_key}
                                                        attachment={attachment}
                                                        showRemoveIcon={false}
                                                    />
                                                </Box>
                                            )) : (
                                                <Text fontSize="14px" color={"#666E82"}>{`There are no attachments`}</Text>
                                            )
                                            :
                                            <Flex w="full" justifyContent="center" alignItems="center">
                                                <PuffLoader
                                                    color="#3D82CE"
                                                    size={80}
                                                />
                                            </Flex>
                                    }
                                </Flex>
                            </Stream>
                        </VStack>

                    </>
                }
            </VStack >
            {
                !isBigScreen &&
                <Box
                    bgColor={"#fff"}
                    minH={["auto", "100px"]} // Adjust height for mobile and above
                    py={[4, 6]} // Add vertical padding for spacing
                    px={[2, 4]} // Add horizontal padding
                    alignContent={"center"}
                >
                    <VStack
                        w={["100%", "90%"]} // Full width for mobile, 90% for larger screens
                        mx={"auto"}
                    >
                        <ButtonGroup
                            gap={4} // Unified gap for better responsiveness
                            flexDirection={["column", "row"]} // Stack buttons vertically on mobile, horizontally on larger screens
                        >
                            {
                                ticketStatus === "approved" && (
                                    <CustomButton
                                        title="Reject"
                                        isDisabled={!isOnline}
                                        onClick={() => onOpen(Mode.Reject)}
                                        ml={'0.5rem'}
                                    />
                                )
                            }
                            {(ticketStatus === "approved" || ticketStatus === "coded") && (
                                <>
                                    <CustomButton
                                        title="Code Only"
                                        isDisabled={!isOnline}
                                        onClick={() => onOpen(Mode.CodeOnly)}
                                        ml={'0.5rem'}
                                    />
                                    <CustomButton
                                        title="Code and Stamp"
                                        isDisabled={!isOnline}
                                        onClick={() => onOpen(Mode.CodeAndStamp)}
                                        ml={'0px'}
                                    />
                                </>
                            )}
                            <CustomButton
                                title="Print"
                                href={isOnline ? `/print/tickets/${id}?withCharge=${true}` : undefined}
                                isDisabled={!isOnline}
                            />
                        </ButtonGroup>
                    </VStack>
                </Box>

            }
            <ModalForm
                title={mode === Mode.Reject ? "Reject Ticket" : "Stamp details"}
                defaultValues={mode === Mode.Reject ? { reason: "" } : defaultValues}
                isOpen={isOpen}
                size={["full", "lg"]}
                onClose={() => setIsOpen(false)}
                onCancel={() => setIsOpen(false)}
                onSave={handleFormSubmit}
                mode={mode}
            >
                {mode === Mode.Reject ? (
                    <RejectTicketForm />
                ) : (
                    <StampForm ticket={ticket} mode={mode} />
                )}
            </ModalForm>
            {isFormSubmit && <OverlayLoader />}
        </Box>

    )
}


