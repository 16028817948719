import { useEffect, useMemo } from "react";
import { formatDateOnly } from "../../app/utils";
import { DetailTile } from "../../components/DetailTile";
import { PanelWrapper } from "../../components/PanelWrapper";
import { TicketResource } from "../../pages/user/TicketDetailPage";
import { Box, Stack, StackDivider, VStack } from "@chakra-ui/react";
import { WrapperProps } from "../../components/TicketDetailWrapper";
import { StatusBadges } from "../../components/widgets/StatusBadges";
import { MessageBox } from "../../components/MessageBox";
import { useMessageBox } from "../../app/hooks";
interface BasicDetailPanelProps extends WrapperProps {
    dataresource?: TicketResource
}

export function BasicDetailPanel({
    dataresource: ticket,
}: BasicDetailPanelProps) {

    const equipmentsDetail = useMemo(() => {
        return ticket?.equipments?.map?.((equipment) => (
            `${equipment.unit} (${equipment?.category?.name})`
        ))
    }, [ticket])?.join(", ")

    const formatAlertMessage = (message: string): string => {
        if (!message) return "";

        // Replace all instances of \n or \r\n with <br/>
        return message
            .replace(/\r?\n/g, '<br/>')
            .replace(/\s{2,}/g, ' ') // Remove extra spaces
            .trim();
    };

    const { showAlert, alertStatus, alertMessage, errorDetail, setAlert, clearAlert } = useMessageBox();

    if (!ticket) return null;

    useEffect(() => {
        if (ticket.status === "customer_rejected") {
            setAlert("error", ticket?.customer_reject_reason || "");
        } else {
            clearAlert()
        }
    }, [ticket])

    return (
        <Box w={{ base: "98%", sm: "95%", md: "90%" }} maxW={"1440px"} p={2} mx="auto">
            <MessageBox
                show={showAlert}
                status={alertStatus}
                message={alertMessage}
                error={errorDetail}
            />
            <PanelWrapper title="General Information" mt={2}>
                <VStack align="stretch" gap={{ base: 0, lg: 3 }} p={2} divider={<StackDivider />}>
                    <Stack direction={{ base: "column", lg: "row" }} w="full" align="flex-start" gap={{ base: 0, lg: 2 }} divider={<StackDivider />}>
                        <DetailTile
                            flexBasis="50%"
                            title="Customer"
                            icon="customer"
                            detail={ticket?.customer.name}
                        />
                        <DetailTile
                            title="Driver"
                            icon="driver"
                            detail={`${ticket?.driver?.first_name} ${ticket?.driver?.last_name}`}
                        />
                    </Stack>
                    <Stack direction={"row"} w="full" gap={6} divider={<StackDivider />}>
                        <DetailTile
                            title="Date"
                            icon="calendar"
                            flexBasis={{ base: "50%", lg: "40%" }}
                            detail={formatDateOnly(ticket?.reference_date)}
                        />
                        <DetailTile
                            title="Status"
                            detail={<StatusBadges status={ticket?.status || ''} />}
                        />
                    </Stack>
                    <Stack direction={{ base: "column", lg: "row" }} w="full" align="flex-start" gap={{ base: 3, lg: 8 }} divider={<StackDivider />}>
                        <Stack direction={{ base: "column", md: "row" }} w="full" gap={{ base: 3, md: 6 }} divider={<StackDivider />}>
                            <DetailTile
                                flexBasis="50%"
                                title="Consignor Contact"
                                icon="phone"
                                detail={ticket?.customer_contact_id
                                    ? `${ticket?.customer_contact?.first_name || ''} ${ticket?.customer_contact?.last_name || ''} (${ticket?.customer_contact?.phone || ''})`
                                    : (ticket?.customer_contact?.phone || '')}
                            />
                            <DetailTile
                                title="Emergency Contact"
                                icon="phone"
                                detail={ticket?.emergency_contact}
                            />
                        </Stack>
                        <DetailTile
                            flexBasis={"60%"}
                            title="Billing Email Address"
                            icon="at"
                            detail={ticket?.customer_billing?.email}
                        />
                    </Stack>
                    <DetailTile
                        title="Equipment"
                        detail={equipmentsDetail}
                        w="full"
                    />
                </VStack>
            </PanelWrapper>
        </Box >
    )
}
