import { useMemo, useState } from 'react';
import { TimeEntryForm } from '../timeEntries/TimeEntryForm';
import { ModalForm } from '../../components/forms/ModalForm';
import { LocalEntry } from '../timeEntries/localEntriesSlice';
import { KnownIcon } from '../../components/widgets/KnownIcon';
import { InputStreak } from '../../components/forms/InputStreak';
import { selectActivityById } from '../activities/activitySlice';
import { CustomButton } from '../../components/forms/CustomButton';
import { WrapperProps } from '../../components/TicketDetailWrapper';
import { ReadonlyField } from '../../components/forms/fields/ReadonlyField';
import { calculateDeltaMinutes, formatMinutesAsHours } from '../../app/utils';
import { Box, HStack, IconButton, StackDivider, Text, useDisclosure, VStack } from '@chakra-ui/react';

interface DriverHoursForm_NEWProps extends WrapperProps {
    timeEntries: LocalEntry[]
    onSave(data: any): void
    onRemove(activity: number): void
    onEdit?(entry: LocalEntry): void
    isdraft?: boolean
}

function DriverHoursForm({
    timeEntries,
    onRemove,
    onSave,
    isdraft
}: DriverHoursForm_NEWProps) {

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedEntry, setSelectedEntry] = useState<LocalEntry | null>(null);
    const [modalMode, setModalMode] = useState<'add' | 'edit'>('add');

    const grouped: {
        [key: string]: {
            minutes: number;
            worker?: string;
            activity?: number
        }
    } = useMemo(() => {
        return timeEntries?.reduce((acc: any, entry: any) => {
            // Create a unique key combining activity and worker (if available)
            const key = entry.worker
                ? `${entry.activity}-${entry.worker}`
                : `${entry.activity}`;

            // Merge both implementations' logic
            acc[key] = {
                minutes: (acc[key]?.minutes || 0) + calculateDeltaMinutes(entry.start, entry.finish),
                worker: isdraft
                    ? `${entry.first_name} ${entry.last_name}`
                    : entry.worker,
                activity: entry.activity
            };
            return acc;
        }, {});
    }, [timeEntries]);

    const handleModalOpen = (mode: 'add' | 'edit', entry?: LocalEntry) => {
        setModalMode(mode);
        setSelectedEntry(entry || null);
        onOpen();
    };
    return (
        <>
            <VStack w="full" alignItems="flex-start" gap={4} p={[0, 2, 4]} divider={<StackDivider />}>
                {
                    timeEntries?.length > 0 ?
                        (Object.entries(grouped).map(([key, { minutes, worker }], index) => {
                            // Split the key back into activity and worker ID
                            const [activity] = key.split('-');

                            const activityEntries = timeEntries.filter(
                                entry => entry.activity === Number(activity)
                            );
                            return (
                                <HStack w="full" alignItems="flex-end" key={index}>
                                    <InputStreak>
                                        <ReadonlyField
                                            label="Worker"
                                            value={worker || "--"}
                                        />
                                        <ReadonlyField
                                            label="Activity"
                                            traitId={Number(activity)}
                                            traitGetter={selectActivityById}
                                        />
                                        <ReadonlyField
                                            label="Total Hours"
                                            value={formatMinutesAsHours(minutes)}
                                        />
                                    </InputStreak>
                                    <HStack>
                                        {(!activityEntries[0].is_approved) &&
                                            <IconButton
                                                aria-label="Edit"
                                                icon={<KnownIcon name="edit" boxSize="24px" cursor="pointer" />}
                                                onClick={() => handleModalOpen('edit', activityEntries[0])}
                                                backgroundColor="transparent"
                                                _hover={{ backgroundColor: "transparent" }}
                                            />
                                        }
                                        {
                                            isdraft &&
                                            <IconButton
                                                aria-label="Remove"
                                                icon={<KnownIcon name="delete" boxSize="24px" cursor="pointer" />}
                                                onClick={() => { onRemove(Number(activity)) }}
                                                backgroundColor="transparent"
                                                _hover={{ backgroundColor: "transparent" }}
                                            />
                                        }
                                    </HStack>
                                </HStack>
                            );
                        }))
                        : (
                            <Box w="full" fontSize="14px" color="#455360" bgColor={"#fff"} borderRadius={"8px"} p={3}>
                                Add Hours
                            </Box>
                        )}
                <Box>
                    {Object.entries(grouped).length > 0 &&
                        <HStack mb={5}>
                            <Text fontSize="14px" color="#455360">Grand Total Hours:</Text>
                            <Box color={"#0E1628"} fontSize={18}>
                                {formatMinutesAsHours(
                                    Object.values(grouped).reduce((total, entry) => total + entry.minutes, 0)
                                )}
                            </Box>
                        </HStack>
                    }
                    <CustomButton
                        title="Add New Entry"
                        variant='outline'
                        onClick={() => handleModalOpen('add')}
                    />
                </Box>
            </VStack>

            <ModalForm
                isdraft={isdraft}
                title={modalMode === 'add' ? 'Add New Entry' : 'Edit Entry'}
                isOpen={isOpen}
                size={["full", "lg"]}
                onClose={onClose}
                onCancel={onClose}
                onSave={onSave}
                defaultValues={selectedEntry}
            >
                <TimeEntryForm
                    isEdit={modalMode === 'edit' ? true : false}
                />
            </ModalForm>
        </>
    )
}

export default DriverHoursForm