import { InputStreak } from '../../components/forms/InputStreak';
import { DigitalSignPadField } from '../../components/forms/fields/DigitalSignPadField';
import BillingToSelect from './BillingToSelect';
import { TicketResource } from '../../pages/user/TicketDetailPage';
import { Mode } from '../../pages/stamp/StampTicketDetail';
import { TextareaField } from '../../components/forms/fields/TextareaField';


export function RejectTicketForm({
    // set props here
}: {
        // set props type  here
    }) {

    return (
        <>
            <InputStreak >
                <TextareaField label="Reason" name="reason" registerOptions={{ required: "This field is required" }} placeholder="Please provide a brief reason for the rejection" />
            </InputStreak>
        </>
    )
}