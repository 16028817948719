import { Box, HStack, StackProps, Text, VStack } from "@chakra-ui/react";
import { KnownIcon, KnownIconName } from "./widgets/KnownIcon";
import { ReactNode } from "react";


interface FilterTileProps extends StackProps {
    icon?: KnownIconName
    title?: string
    detail?: string | ReactNode
}

export function FilterTile({
    icon,
    title,
    detail,
    ...rest
}: FilterTileProps) {
    return (
        <Box w="full" bgColor={"#fff"} borderRadius={"8px"} p={3}>
            <VStack align="stretch" gap={{ base: 2, lg: 4 }} {...rest}>
                {title && <Text fontSize={{ base: "12px", md: "14px" }} color="#455360" fontWeight={400}>{title}</Text>}
                <HStack gap={3} alignItems='center' >
                    {icon && <KnownIcon name={icon} boxSize={"24px"} />}
                    <Box
                        w="full"
                        fontSize={{ base: "16px", lg: "20px" }}
                        fontWeight={500}
                        color={"black"}
                    >
                        {detail ?? '--'}
                    </Box>
                </HStack>
            </VStack>
        </Box>
    )
}
