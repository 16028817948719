import {
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  Input,
  useMediaQuery,
} from "@chakra-ui/react"
import { useFormContext, RegisterOptions } from "react-hook-form"

interface LsdPrefixProps {
  label?: string
  name: string
  registerOptions?: RegisterOptions
  helper?: string
}

export function LsdPrefixField({
  label,
  name,
  registerOptions,
  helper,
}: LsdPrefixProps) {
  const { register, formState } = useFormContext()
  const [isSmallScreen] = useMediaQuery("(max-width: 570px)")

  // Validation function to check for a valid 3-digit number
  const validateLsdPrefix = (value: string | undefined) => {
    if (value) {
      const isValidLsdPrefix = /^\d{3}$/.test(value)  // Validate exactly 3 digits
      if (isValidLsdPrefix) {
        return undefined // Valid input
      } else {
        return "Invalid format. Please enter 3 digits"
      }
    }
  }

  // Default registration options
  const defaultRegisterOptions = {
    validate: {
      validLsdPrefix: validateLsdPrefix,
    },
  }

  // Merge provided registerOptions with default options
  const mergedRegisterOptions = {
    ...defaultRegisterOptions,
    ...registerOptions,
  }

  // Handler to allow only numeric values
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Remove any non-numeric characters
    const newValue = e.target.value.replace(/\D/g, '') // Replace all non-digits with empty string
    e.target.value = newValue
  }

  return (
    <FormControl isInvalid={!!formState.errors?.[name]} w={isSmallScreen ? "full" : "35%"}>
      <FormLabel color="#455360">{label}</FormLabel>
      <Input
        {...register(name, mergedRegisterOptions)}
        id={name}
        h="48px"
        borderRadius="4px"
        variant="filled"
        inputMode="numeric" // Ensures numeric keyboard on mobile devices
        placeholder="###"
        maxLength={3} // Limits the input to 3 characters
        color="#0E1628"
        type="text" // Use type="text" for maxLength to work
        onInput={handleInputChange} // Filter out non-numeric input
      />
      <FormHelperText>{helper}</FormHelperText>
      <FormErrorMessage>
        {formState.errors?.[name]?.message?.toString()}
      </FormErrorMessage>
    </FormControl>
  )
}
