import { PanelWrapper } from "../../components/PanelWrapper";
import { KnownIcon } from "../../components/widgets/KnownIcon";
import { TicketResource } from "../../pages/user/TicketDetailPage";
import { WrapperProps } from "../../components/TicketDetailWrapper";
import { Box, HStack, Spacer, Stack, StackDivider, Text, useBreakpointValue, VStack } from "@chakra-ui/react"


interface LocationDetailProps extends WrapperProps {
    dataresource?: TicketResource
}

export function LocationsDetailsPanel({
    dataresource: ticket,

}: LocationDetailProps) {
    if (!ticket) return null;
    const isBreakpoint = useBreakpointValue({ base: true, md: false });
    return (
        <Box w={{ base: "98%", sm: "95%", md: "90%" }} maxW={"1440px"} p={2} mx="auto">
            {(ticket?.consignor_terminal || ticket?.consignee_terminal) &&
                <PanelWrapper title="Locations">
                    <VStack align="stretch" p={2} divider={<StackDivider />}>
                        <Stack direction={isBreakpoint ? "column" : "row"} alignItems={"stretch"}>
                            <VStack alignItems={"flex-start"} minW={"200px"}>
                                <Text color={"#666E82"} fontSize={{ base: 12, md: 14 }}>From</Text>
                                <HStack alignItems={"flex-start"} w="full">
                                    <KnownIcon name="location" boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                    <Box w="full" wordBreak="break-word" whiteSpace="normal" overflow="hidden">
                                        <Text fontSize={"18px"} color={"#0E1628"}>{ticket?.consignor_terminal?.name || "--"} </Text>
                                        <Text fontSize={"14px"} color={"#666E82"}>{ticket?.consignor_lsd || "--"} </Text>
                                        <Text fontSize={"14px"} color={"#666E82"}>{ticket?.consignor_location?.name || "--"} </Text>
                                    </Box>
                                </HStack>
                            </VStack>
                            <Spacer my={"auto"} h={0} border={"1px dashed #9F9F9F"} display={isBreakpoint ? "none" : "block"} />
                            <VStack alignItems={"flex-start"} minW={"200px"}>
                                <Text color={"#666E82"} fontSize={{ base: 12, md: 14 }}>To</Text>
                                <HStack alignItems={"flex-start"}>
                                    <KnownIcon name="location" boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                    <Box w="full" wordBreak="break-word" whiteSpace="normal" overflow="hidden">
                                        <Text fontSize={"18px"} color={"#0E1628"} >{ticket?.consignee_terminal?.name || "--"} </Text>
                                        <Text fontSize={"14px"} color={"#666E82"}>{ticket?.consignee_lsd || "--"} </Text>
                                        <Text fontSize={"14px"} color={"#666E82"}>{ticket?.consignee_location?.name || "--"} </Text>
                                    </Box>
                                </HStack>
                            </VStack>
                        </Stack>
                    </VStack>
                </PanelWrapper>
            }
        </Box>
    )
}

