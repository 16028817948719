import { useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { KnownIcon, KnownIconName } from './widgets/KnownIcon';
import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex, HStack, StackDivider, Text } from '@chakra-ui/react'

export type BreadcrumbItemType = {
    label: string;
    path?: string;
    icon?: KnownIconName;
    isCurrentPage?: boolean;
};

export function BreadCrumbs({ items }: { items: BreadcrumbItemType[] }) {
    const navigate = useNavigate()

    // Reference to the Box element that wraps the breadcrumbs
    const breadcrumbContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // Ensure the component has rendered and the scroll position is correctly updated
        const handleScroll = () => {
            if (breadcrumbContainerRef?.current) {
                const container = breadcrumbContainerRef?.current;
                if (container?.scrollWidth > container?.clientWidth) {
                    // Use `scrollTo` with smooth behavior for animated scroll
                    container.scrollTo({
                        left: container?.scrollWidth,
                        behavior: 'smooth', // This makes the scroll smooth
                    })
                }
            }
        };
        // Set scroll to the right after a slight delay to ensure items are rendered
        const timeoutId = setTimeout(handleScroll, 100);

        return () => clearTimeout(timeoutId); // Cleanup timeout on component unmount
    }, [items]); // Scroll when items change

    return (
        <>
            <HStack w="full" gap={[3, 5]} fontSize={12} divider={<StackDivider w={"2px"} bgColor={"#DDDDDD"} />}>
                <Flex gap={2} color={"#7C8DB5"} alignItems={"center"} onClick={() => navigate(-1)} cursor={"pointer"} >
                    <KnownIcon name="previous" mr={2} />
                    <Text >Back</Text>
                </Flex>
                <Box
                    overflowX={"auto"}
                    ref={breadcrumbContainerRef}   // add ref for scrollLeft
                    sx={{
                        // Hide scrollbar for Webkit-based browsers (Chrome, Safari, Edge)
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                        // Hide scrollbar for Firefox
                        '&': {
                            scrollbarWidth: 'none', // Hides the scrollbar
                        },
                    }}
                >
                    <Breadcrumb separator={<KnownIcon name="divider" boxSize={"20px"} />}>
                        {items.map((item, index) => (
                            <BreadcrumbItem key={index} isCurrentPage={item.isCurrentPage} flexShrink={0}>
                                {item.path ? (
                                    <BreadcrumbLink as={Link} to={item.path} textDecoration={'none'}  >
                                        <Flex gap={2} color={item.isCurrentPage ? "#0E1628" : "#7C8DB5"} alignItems={"center"}>
                                            {item.icon && <KnownIcon name={item.icon} />}
                                            {item?.label || ''}
                                        </Flex>
                                    </BreadcrumbLink>
                                ) : (
                                    <Box color={item.isCurrentPage ? "#0E1628" : "#7C8DB5"}   >
                                        <Flex gap={2} alignItems={"center"}>
                                            {item.icon && <KnownIcon name={item.icon} />}
                                            {item?.label || ''}
                                        </Flex>
                                    </Box>
                                )}
                            </BreadcrumbItem>
                        ))}
                    </Breadcrumb>
                </Box>
            </HStack>
        </>
    )
}
