import { formatTimeOnly, getDateString } from "../app/utils";
import { DetailHeading } from "./DetailHeading";
import { ModalForm } from "./forms/ModalForm";
import { KnownIcon } from "./widgets/KnownIcon";
import { TimeEntryForm } from "../features/timeEntries/TimeEntryForm";
import { Box, Card, CardBody, Flex, HStack, IconButton, Spacer, Stack, StackDivider, Text, useDisclosure, useMediaQuery, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { usePartialUpdateTimeEntryMutation } from "../features/timeEntries/timeEntriesApi";
import { StatusBadges } from "./widgets/StatusBadges";
import { useConfigCheck } from "../app/hooks";

interface timeEntrey {
    id: 275,
    activity: number,
    activity__name: string,
    is_approved: boolean,
    date: string,
    customer: string,
    start: string,
    finish: string,
    hours: number,
    description: string
}
interface timeEntryDetail {
    date: string,
    total_hours: number,
    entries: timeEntrey[]
}
interface TimeEntryCardProps {
    timeEntry: timeEntryDetail
}
export default function TimeEntryCard({ timeEntry }: TimeEntryCardProps) {
    const { isOpen, onClose, onOpen } = useDisclosure();
    const { userProfile: { role } = {} } = useConfigCheck();
    const [isBreakpoint] = useMediaQuery("(min-width: 1200px)");
    const [partialUpdateTimeEntry] = usePartialUpdateTimeEntryMutation();

    // set default value for time entry form
    const [defaultValues, setDefaultValues] = useState({})
    const handleTimeEntry = (entry: timeEntrey) => {
        const { id, start, finish, activity, description } = entry
        setDefaultValues({
            id: id,
            activity: activity,
            start: start,
            finish: finish,
            description: description,
        })
        onOpen();
    }
    const handleSaveTimeEntry = async (data: any) => {
        partialUpdateTimeEntry({ id: data.id, ...data })
    }
    return (
        <>
            {
                // isBreakpoint ?
                //     <Card w="full" >
                //         <CardBody p={{ base: 2, lg: 5 }}>
                //             <Stack p={2} divider={<StackDivider />} spacing='4' color={"#0E1628"}>
                //                 <Box>
                //                     <DetailHeading title={`${getDateString(timeEntry.date)}`} />
                //                     <DetailHeading title={`${timeEntry.total_hours.toFixed(2)} Hours`} fontWeight={400} fontSize={"14px"} color={"#666E82"} />
                //                 </Box>
                //                 <Box width="100%">
                //                     <HStack
                //                         mb={4}
                //                         fontSize={[12, 14, 16]}
                //                         color="#455360"
                //                         textAlign="left"
                //                         spacing={0}
                //                         divider={<StackDivider />}
                //                     >
                //                         <Text flexBasis="30%">Task</Text>
                //                         <Text flexBasis="40%" px={{ base: 2, sm: 5 }}>Company</Text>
                //                         <Text flexBasis="30%" px={{ base: 2, sm: 5 }}>Time</Text>
                //                         <Text flexBasis="30%" px={{ base: 2, sm: 5 }}>Status</Text>
                //                     </HStack>

                //                     <VStack align="stretch" spacing={4}>
                //                         {timeEntry.entries.map((entry: timeEntrey, index: number) => (
                //                             <HStack key={index} alignItems="center" color={"#0E1628"} spacing={0} divider={<StackDivider />}>
                //                                 <HStack flexBasis="30%" my={4}>
                //                                     <KnownIcon name="activity" display={"inline"} boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                //                                     <Text fontSize={[12, 14, 16]}>{entry.activity__name}</Text>
                //                                 </HStack>
                //                                 <HStack flexBasis="40%" alignItems="center" px={{ base: 2, sm: 5 }} my={4}>
                //                                     <KnownIcon name="customer" display={"inline"} boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                //                                     <Box w="full" wordBreak="break-word" whiteSpace="normal" overflow="hidden">
                //                                         <Text
                //                                             fontWeight={500}
                //                                             noOfLines={2}
                //                                             fontSize={[12, 14, 16]}
                //                                         >
                //                                             {entry.customer || "No Customer"}
                //                                         </Text>
                //                                     </Box>
                //                                 </HStack>
                //                                 <HStack flexBasis="30%" alignItems="center" px={{ base: 2, sm: 5 }} my={4} justifyContent={"space-between"}>
                //                                     <Flex gap={2}>
                //                                         <KnownIcon name="clock" display={"inline"} boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                //                                         <Text fontSize={[12, 14, 16]}>{`${entry.hours.toFixed(2)} Hours`}</Text>
                //                                     </Flex>
                //                                     {/* {
                //                                         !entry.customer && !entry.is_approved &&
                //                                         <IconButton
                //                                             aria-label="edit"
                //                                             icon={<KnownIcon name="edit" boxSize={{ base: "16px", md: "20px", lg: "24px" }} cursor="pointer" />}
                //                                             onClick={() => handleTimeEntry(entry)}
                //                                             backgroundColor="transparent"
                //                                             _hover={{ backgroundColor: "transparent" }}
                //                                             boxSize={{ base: "16px", md: "20px", lg: "24px" }}
                //                                         />
                //                                     } */}
                //                                 </HStack>
                //                                 <HStack flexBasis="30%" alignItems="center" px={{ base: 2, sm: 5 }} my={4} justifyContent={"space-between"}>

                //                                     <StatusBadges status={entry.is_approved ? "approved" : "unapproved"} />
                //                                     {
                //                                         !entry.customer && !entry.is_approved &&
                //                                         <IconButton
                //                                             aria-label="edit"
                //                                             icon={<KnownIcon name="edit" boxSize={{ base: "16px", md: "20px", lg: "24px" }} cursor="pointer" />}
                //                                             onClick={() => handleTimeEntry(entry)}
                //                                             backgroundColor="transparent"
                //                                             _hover={{ backgroundColor: "transparent" }}
                //                                             boxSize={{ base: "16px", md: "20px", lg: "24px" }}
                //                                         />
                //                                     }
                //                                 </HStack>
                //                             </HStack>
                //                         ))}
                //                     </VStack>
                //                 </Box>

                //             </Stack>
                //         </CardBody>
                //     </Card > :
                <Card w="full">
                    <CardBody p={{ base: 2, lg: 5 }}>
                        <Stack p={2} divider={<StackDivider />} spacing='4' color={"#0E1628"}>
                            <HStack justifyContent={'space-between'}>
                                <Text fontWeight={600}>{`${getDateString(timeEntry.date)}`}</Text>
                                <HStack alignItems="flex-start">
                                    <KnownIcon name="clock" display={"inline"} boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                    <Text fontSize={[12, 14, 16]}>{`${Number(timeEntry.total_hours).toFixed(2)} Hours`}</Text>
                                </HStack>
                            </HStack>
                            {timeEntry.entries.map((entry: timeEntrey, index: number) => (
                                <VStack key={index} alignItems='stretch' gap={2}>
                                    <HStack justifyContent={'space-between'} gap={0} alignItems={'center'}>
                                        <HStack flexBasis={["60%", "70%"]} alignItems="center" >
                                            <KnownIcon name="customer" display={"inline"} boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                            <Box w="full" wordBreak="break-word" whiteSpace="normal" overflow="hidden">
                                                <Text fontWeight={500} noOfLines={2} fontSize={[12, 14, 16]}>{entry.customer || "No Customer"}</Text>
                                            </Box>
                                        </HStack>
                                        <Flex minW={{ base: "120px", lg: "150px" }} p={1} gap={'10px'} fontSize={[12, 14]} borderRadius={"40px"} bgColor={'#EBEEFF'} justifyContent={"center"} alignItems={"center"}>
                                            <KnownIcon name="clock" display={"inline"} boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                            <Text noOfLines={1} isTruncated>{`${Number(entry.hours).toFixed(2)} Hours`}</Text>
                                        </Flex>
                                    </HStack>
                                    <HStack justifyContent={'space-between'} gap={0} alignItems={'center'}>
                                        <HStack alignItems={'center'}>
                                            <KnownIcon name="activity" display={"inline"} boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                            <Text fontSize={[12, 14, 16]}>{entry.activity__name}</Text>
                                        </HStack>
                                        <HStack>
                                            {/* {
                                                !entry.customer && !entry.is_approved &&
                                                <IconButton
                                                    aria-label="edit"
                                                    icon={<KnownIcon name="edit" boxSize={{ base: "16px", md: "20px", lg: "24px" }} cursor="pointer" />}
                                                    onClick={() => handleTimeEntry(entry)}
                                                    backgroundColor="transparent"
                                                    _hover={{ backgroundColor: "transparent" }}
                                                    boxSize={{ base: "16px", md: "20px", lg: "24px" }}
                                                />
                                            } */}
                                            <StatusBadges status={entry.is_approved ? "approved" : "submitted"} />
                                        </HStack>
                                    </HStack>
                                    <HStack justifyContent={"space-between"}>
                                        <HStack w={"70%"}>
                                            <Box w="fit-content" minW={"100px"}>
                                                <Text color={"#666E82"} fontSize={{ base: 12, md: 14 }}>Start Time</Text>
                                                <Flex gap={{ base: "6px", lg: 3 }} alignItems={"center"}>
                                                    <KnownIcon name="clock" boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                                    <Text fontSize={{ base: "12px", sm: "14px", lg: "16px" }} >{formatTimeOnly(entry?.start) || "--"} </Text>
                                                </Flex>
                                            </Box>
                                            <Spacer borderBottom={"2px dashed #9F9F9F"} />
                                            <Box w="fit-content" minW={"100px"}>
                                                <Text color={"#666E82"} fontSize={{ base: 12, md: 14 }}>End Time</Text>
                                                <Flex gap={{ base: "6px", lg: 3 }} alignItems={"center"}>
                                                    <KnownIcon name="clock" boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                                    <Text fontSize={{ base: "12px", sm: "14px", lg: "16px" }}  >{formatTimeOnly(entry?.finish) || "--"} </Text>
                                                </Flex>
                                            </Box>
                                        </HStack>
                                        <Flex minW={{ base: "fit-content", sm: "120px", lg: "150px" }} alignItems={"center"} justifyContent={"center"}>
                                            {
                                                (role === "driver" || role === "swamper") && !entry.customer && !entry.is_approved &&
                                                <IconButton
                                                    aria-label="edit"
                                                    icon={<KnownIcon name="edit" boxSize={{ base: "16px", md: "20px", lg: "24px" }} cursor="pointer" />}
                                                    onClick={() => handleTimeEntry(entry)}
                                                    backgroundColor="transparent"
                                                    _hover={{ backgroundColor: "transparent" }}
                                                    boxSize={{ base: "16px", md: "20px", lg: "24px" }}
                                                />
                                            }
                                        </Flex>
                                    </HStack>
                                </VStack>
                            ))}
                        </Stack>
                    </CardBody>
                </Card >
            }
            <ModalForm
                isdraft={false}
                title={`Edit Time Entry`}
                isOpen={isOpen}
                size={["full", "lg"]}
                defaultValues={defaultValues}
                onClose={onClose}
                onCancel={onClose}
                onSave={handleSaveTimeEntry}
            >
                <TimeEntryForm isEdit={true} />
            </ModalForm>
        </>
    )
}
