import { Box } from "@chakra-ui/react";
import { BasicForm } from "./BasicForm";
import { DraftBasicValues } from "./tickets";
import { Container } from "../../components/Container";
import { EditForm } from "../../components/forms/EditForm";
import { PanelWrapper } from "../../components/PanelWrapper";
import { OverlayLoader } from "../../components/OverlayLoader";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { TicketResource } from "../../pages/user/TicketDetailPage";
import { CustomButton } from "../../components/forms/CustomButton";
import { WrapperProps } from "../../components/TicketDetailWrapper";
import { saveDraftBasic, selectDraftBasic } from "../draft/draftSlice";
import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { useAppDispatch, useAppSelector, useCustomToast } from "../../app/hooks";
import { removeEquipment, selectTicketBasic, updateTicketBasic } from "./ticketsSlice";

interface BasicPanelProps extends WrapperProps {
    scrollTo?(name: string): void,
    dataresource?: TicketResource,
}

export const BasicPanel = forwardRef(
    function BasicPanel({ dataresource, scrollTo, isdraft, resourceid }: BasicPanelProps, ref) {
        const dispatch = useAppDispatch()
        const { showToast } = useCustomToast()
        const isManualReset = useRef(false);
        //Selector based on draft or ticket
        const selectResource = isdraft
            ? useAppSelector(selectDraftBasic(Number(resourceid)))
            : useAppSelector(selectTicketBasic(Number(resourceid))) || {};

        const methods = useForm<DraftBasicValues>({ defaultValues: selectResource })
        const { control, handleSubmit, formState: { isSubmitting }, reset, trigger } = methods

        // Watch form values to detect changes
        const watchedValues = useWatch<DraftBasicValues>({
            control,
            defaultValue: selectResource,
        })
        const hasChanged = JSON.stringify(watchedValues) !== JSON.stringify(selectResource)

        /* Save handler based on draft or ticket
         * Save to local storage whenever watched values change for draft (AutoSave)
         */
        const handleSaveResource = async (data: any) => {
            if (!isdraft) {
                try {
                    await dispatch(updateTicketBasic({ id: resourceid, ...data })).unwrap()
                    isManualReset.current = true;
                    scrollTo?.("LocationsForm")
                } catch (error) {
                    console.error("API error:", error);
                    showToast({
                        status: 'error',
                        description: "Something went wrong",
                    });
                }
            }
        }
        //auto save handler  draft
        useEffect(() => {
            if (isdraft) {
                const filteredEquipments = watchedValues?.equipments?.filter((item: any) => item && typeof item === "number")
                dispatch(saveDraftBasic({ id: Number(resourceid), ...watchedValues, equipments: filteredEquipments } as DraftBasicValues))
            };
        }, [watchedValues, resourceid, dispatch, isdraft]);

        // Equipment remove handler
        const handleEquipmentRemove = async (index: number) => {
            const equipmentId = dataresource?.equipments && dataresource?.equipments?.[index]
            await dispatch(removeEquipment({ id: Number(resourceid), ticket_equipment_id: Number(equipmentId) }))
        }

        // reset form after save event to track any new change in form
        useEffect(() => {
            if (isManualReset.current) {
                reset(selectResource);
            }
            isManualReset.current = false;
        }, [selectResource, reset, methods]);


        // Expose the `trigger` method to the parent
        useImperativeHandle(ref, () => ({
            triggerValidation: () => trigger(), // Expose trigger validation
            resetForm: () => reset() // Optionally expose reset
        }));

        return (
            <Box w="full">
                {isSubmitting && <OverlayLoader />}
                <Container>
                    <FormProvider {...methods}>
                        <EditForm onSave={handleSubmit(handleSaveResource)}>
                            <PanelWrapper title="General Information">
                                {hasChanged && !isdraft && <CustomButton title="Save" type="submit" variant="outline" w={["80px"]} size={"sm"} isLoading={isSubmitting} />}
                                <BasicForm onRemove={!isdraft ? handleEquipmentRemove : undefined} />
                            </PanelWrapper>
                        </EditForm>
                    </FormProvider>
                </Container>
            </Box>
        )
    })
