import React, { useMemo } from "react";
import { FieldProps } from "../../forms";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { selectAllActivities } from "../activities/activitySlice";
import { SelectField } from "../../components/forms/fields/SelectField";

interface ActivitySelectProps extends FieldProps {
  isRequired?: boolean,
  disabled?: boolean;
}

const ActivitySelect: React.FC<ActivitySelectProps> = (
  props: ActivitySelectProps,
) => {
  const localtion = useLocation().pathname
  const activities = useAppSelector(selectAllActivities)

  const options = useMemo(() => {
    if (localtion.includes("user/draft")) {
      return activities
        .filter((item) => item.is_active && !item.description_field)
        .map((item) => ({ value: item.id, label: item.name }));
    }
    return activities
      .filter((item) => item.is_active)
      .map((item) => ({ value: item.id, label: item.name }));
  }, [activities]);

  return <SelectField {...props} options={options} isRequired={props.isRequired} disabled={props.disabled} />
}

export default ActivitySelect
