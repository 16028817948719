import { RootState } from "../../app/store";
import { components } from "../../app/api/schema";
import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import { coordinatesApi } from "./coordinatesApi";

export type coordinatesResource = components["schemas"]["Coordinate"]

const coordinatesAdapter = createEntityAdapter<coordinatesResource>({
  selectId: (lsd) => lsd as any, // the string itself is the ID
})

const initialState = coordinatesAdapter.getInitialState({
  status: "idle",
  error: null,
})

const coordinatesSlice = createSlice({
  name: "coordinates",
  initialState,
  reducers: {
    // Reducer functions will go here if needed
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      coordinatesApi.endpoints.listCoordinates.matchFulfilled,
      (state, action) => {
        // Check if lsd_list is an array
        if (Array.isArray(action.payload.lsd_list)) {
          const allLsds = action.payload.lsd_list;  // Directly access the lsd_list array
    
          // Update the state using coordinatesAdapter
          coordinatesAdapter.setAll(state, allLsds as any);
        } else {
          console.error('Expected lsd_list to be an array.');
        }
        // const allLsds = action.payload.flatMap(list => list.lsd_list);
        // lsdsAdapter.setAll(state, allLsds);
      }
    )
    builder.addMatcher(
      coordinatesApi.endpoints.createCoordinates.matchFulfilled,
      (state, action) => {
        coordinatesAdapter.addOne(state, action.payload)
      },
    )
    builder.addMatcher(
      coordinatesApi.endpoints.retrieveCoordinates.matchFulfilled,
      (state, action) => {
        coordinatesAdapter.upsertOne(state, action.payload)
      },
    )
    builder.addMatcher(
      coordinatesApi.endpoints.updateCoordinates.matchFulfilled,
      (state, action) => {
        coordinatesAdapter.upsertOne(state, action.payload)
      },
    )
    builder.addMatcher(
      coordinatesApi.endpoints.partialUpdateCoordinates.matchFulfilled,
      (state, action) => {
        coordinatesAdapter.upsertOne(state, action.payload)
      },
    )
    builder.addMatcher(
      coordinatesApi.endpoints.destroyCoordinates.matchFulfilled,
      (state, action) => {
        coordinatesAdapter.removeOne(state, action.meta.arg.originalArgs)
      },
    )
  },
})

export default coordinatesSlice.reducer

export const {
  selectAll: selectAllCoordinates,
  selectById: selectCoordinateById,
  selectIds: selectCoordinateIds,
  selectEntities: selectCoordinateEntities,
  selectTotal: selectTotalCoordinates,
} = coordinatesAdapter.getSelectors<RootState>((state) => state.coordinates)
