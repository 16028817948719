import { useEffect } from "react";
import { getLogo } from "../../app/utils";
import { Flex, VStack, Image, Text } from "@chakra-ui/react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector, useConfigCheck } from "../../app/hooks";
import { loadSession, selectIsLoggedIn } from "../../features/session/sessionSlice";

export function AuthArea() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { compConfig } = useConfigCheck();
  const isLoggedIn = useAppSelector(selectIsLoggedIn);

  useEffect(() => {
    dispatch(loadSession())
  }, [dispatch])

  useEffect(() => {
    if (isLoggedIn && location.pathname !== "/auth/logout") {
      navigate("/user");
    }
  }, [isLoggedIn, location.pathname, navigate]);

  return (
    <Flex
      bg="#F8F8FB"
      justify="center" align="start"
      minH="100vh"
      paddingX={{ base: 4, md: 8, lg: 16 }}
    >
      <VStack
        alignItems={"center"}
        w={{ base: '100%', sm: 450, md: 500 }}
        marginY={"auto"}
      >
        <Image src={compConfig?.large_logo || getLogo(import.meta.env.VITE_ENV_MODE, "large")} fallbackSrc={getLogo(import.meta.env.VITE_ENV_MODE, "large")} w={[145, 185, 220]} h={"auto"} maxH={"120px"} loading="lazy" objectFit={"contain"} />
        <Outlet />
        <Text fontSize={"14px"} fontWeight={400} color={"#455360"} mt={2}>&copy; 2024 DIMEC Inc.</Text>
      </VStack>
    </Flex>
  )
}
