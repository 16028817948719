import { FieldProps } from "../../../forms";
import { useFormContext } from "react-hook-form";
import { KnownIcon } from "../../widgets/KnownIcon";
import { FormControl, FormLabel, FormHelperText, FormErrorMessage, InputGroup, InputLeftAddon, VStack, Input, } from "@chakra-ui/react";

interface InputFieldProps extends FieldProps {
  inputMode?: "search" | "text" | "none" | "tel" | "url" | "email" | "numeric" | "decimal",
  hidden?: boolean;
}

export function InputField({
  label,
  name,
  registerOptions,
  helper,
  readOnly,
  placeholder,
  icon,
  inputMode,
  hidden = false,
}: InputFieldProps) {

  const { register, formState: { errors } } = useFormContext()

  // If hidden is true, render a hidden input
  if (hidden) {
    return (
      <input
        type="hidden"
        {...register(name, registerOptions)}
      />
    );
  }

  return (
    <FormControl isInvalid={errors[name] !== undefined}>
      <VStack w="full" gap="10px" alignItems="flex-start">
        <FormLabel color="#455360" >{label} {registerOptions?.required && <span style={{ color: 'red' }}>*</span>}</FormLabel>
        <InputGroup>
          {
            icon &&
            <InputLeftAddon h={"48px"} border="none">
              <KnownIcon name={icon} />
            </InputLeftAddon>
          }
          <Input
            id={name}
            {...register(name, registerOptions)}
            h={"48px"}
            borderRadius={"4px"}
            variant="filled"
            color={"#0E1628"}
            placeholder={placeholder}
            readOnly={readOnly}
            inputMode={inputMode}
          />
        </InputGroup>
        {helper && <FormHelperText>{helper}</FormHelperText>}
        <FormErrorMessage>{errors?.[name]?.message?.toString()}</FormErrorMessage>
      </VStack>
    </FormControl>
  )
}
