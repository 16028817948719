import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { usersApi } from "./usersApi";
import { components } from "../../app/api/schema";

// More comprehensive type for User
type UserResource = components["schemas"]["User"]

// Create an entity adapter for users
const usersAdapter = createEntityAdapter<UserResource>({
  // Use username as the primary identifier if that makes sense for your use case
  selectId: (user) => user.id || "",
  
});

// Define the shape of the slice state
export interface UserState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

// Initial state with additional type safety
const initialState = usersAdapter.getInitialState<UserState>({
  status: 'idle',
  error: null,
});

// Create the user slice
const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    
  },
  extraReducers: (builder) => {
    // Handle successful user list fetch
    builder.addMatcher(
      usersApi.endpoints.listUsers.matchFulfilled,
      (state, action) => {
        state.status = 'succeeded';
        usersAdapter.setAll(state, action.payload);
      }
    )
    // Handle user list fetch failure
    .addMatcher(
      usersApi.endpoints.listUsers.matchRejected,
      (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch users';
      }
    )
    // Handle user list fetch loading
    .addMatcher(
      usersApi.endpoints.listUsers.matchPending,
      (state) => {
        state.status = 'loading';
      }
    );
  }
});

// Export selectors
export const {
  selectAll: selectAllUsers,
  selectById: selectUserById,
} = usersAdapter.getSelectors<RootState>((state) => state.users);

export default userSlice.reducer;