import { EmailField } from '../../components/forms/fields/EmailField'
import { InputField } from '../../components/forms/fields/InputField'
import { PhoneField } from '../../components/forms/fields/PhoneField'

function ConsignorContactForm() {
    return (
        <>
            <InputField label="First Name" name="first_name" placeholder="Write it here..." registerOptions={{ required: "First Name is required" }} />
            <InputField label="Last Name" name="last_name" placeholder="Write it here..." registerOptions={{ required: "Last Name is required" }} />
            <PhoneField label="Contact Number" name="phone" registerOptions={{ required: "Contact number is required" }} />
            <EmailField label="Email" name="email" registerOptions={{ required: "Email is required" }} />
        </>
    )
}

export default ConsignorContactForm
