import { getLogo } from "../../app/utils"
import tankerLogo from "/images/tanker1.webp"
import { useLocation } from "react-router-dom"
import { useConfigCheck } from "../../app/hooks"
import { Box, Card, CardBody, Flex, Image } from "@chakra-ui/react"
interface FocusViewProps {
  children: React.ReactNode
}

export function FocusView({ children }: FocusViewProps) {
  const pathname = useLocation().pathname
  const { compConfig } = useConfigCheck()

  return (
    <Box bg="white" rounded="lg" w="full">
      <Card maxW='xl'>
        {
          pathname.includes("/auth/login") &&
          <Flex bg="#EBEEFF" alignItems="center" justifyContent={"center"} minH={"130px"} mb={2}>
            <Image
              src={tankerLogo}
              width={[200, 235]}
            />
          </Flex>
        }
        <CardBody position="relative" p={3} >
          {
            pathname.includes("/auth/login") &&
            <Flex w={"full"} justifyContent="center" >
              <Box h={"4rem"} w={"4rem"} position={"absolute"} top={"-40px"}>
                <Flex borderRadius={"50%"} h="full" w="full" justifyContent="center" alignItems="center" bg="#FFFFFF">
                  <Image
                    w={"80%"}
                    src={compConfig?.small_logo || getLogo(import.meta.env.VITE_ENV_MODE, "small")}
                    fallbackSrc={getLogo(import.meta.env.VITE_ENV_MODE, "small")}
                    alt={compConfig?.name}
                    borderRadius="full"
                    loading="lazy"
                  />
                </Flex>
              </Box>
            </Flex>
          }
          <Box w="full" p={5} mt={5} color="#455360">
            {children}
          </Box>
        </CardBody>
      </Card>
    </Box>
  )
}
