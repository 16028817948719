import { useState, ChangeEvent, useMemo, useEffect } from "react"
import {
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  InputGroup,
  Input,
  List,
  ListItem,
  Box,
  Text,
  VStack,
  Button,
  InputRightElement,
  useMediaQuery
} from "@chakra-ui/react";
import { useFormContext, RegisterOptions } from "react-hook-form";

export interface AutocompleteSuggestion {
  value: number | string;
  text: string
}

interface LsdAutocompleteFieldProps {
  label?: string;
  name: string;
  registerOptions?: RegisterOptions;
  helper?: string;
  suggestions?: AutocompleteSuggestion[];
  onMatch?: (selectedSuggestion: AutocompleteSuggestion) => void;
  isPrefix?: boolean;
  w?: string;
  maxLength?: number;
  placeholder?: string;
}

export function LsdAutocompleteField({
  label,
  name,
  registerOptions,
  helper,
  suggestions = [],
  onMatch,
  isPrefix = false,
  w,
  maxLength,
  placeholder
}: LsdAutocompleteFieldProps) {
  const { register, setValue, formState, trigger, watch } = useFormContext();
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [filteredSuggestions, setFilteredSuggestions] = useState<AutocompleteSuggestion[]>([]);
  const [isSmallScreen] = useMediaQuery("(max-width: 570px)");
  const [isNewValue, setIsNewValue] = useState(false);

  const currentValue = watch(name);
  const [nameField, fieldType] = name.split(".");

  const transformToLsdFormat = (value: string) => {
    if (value.trim().length === 0) {
      setValue(name, "");
      return "";
    }

    if (isPrefix) {
      const numericValue = value.replace(/[^0-9]/g, "");
      setValue(name, numericValue);
      return numericValue;
    } else {
      const alphanumericValue = value.replace(/[^a-zA-Z0-9]/g, "").toUpperCase();
      const formattedValue = `${alphanumericValue.slice(0, 2)}-${alphanumericValue.slice(2, 4)}-${alphanumericValue.slice(4, 6)}-${alphanumericValue.slice(6, 8)}-${alphanumericValue.slice(8, 10)}`;
      setValue(name, formattedValue);
      return formattedValue;
    }
  };

  const validateField = (value: string | undefined) => {
    if (!value) return undefined;

    if (isPrefix) {
      const isValidPrefix = /^\d{3}$/.test(value);
      return isValidPrefix ? undefined : "Invalid format. Please enter 3 digits";
    } else {
      const isValidLsd = /^(?:\d{2}|NE|NW|SE|SW)-\d{2}-\d{2}-\d{2}-W\d$/.test(value);
      return isValidLsd ? undefined : "Invalid LSD format. Please use ##-##-##-##-W#";
    }
  };

  const checkIfValueExists = (value: string) => {
    return suggestions.some(suggestion =>
      suggestion.value.toString().toLowerCase() === value.toLowerCase() ||
      suggestion.text.toLowerCase() === value.toLowerCase()
    );
  };
  useEffect(() => {
    if (currentValue) {
      setIsNewValue(currentValue.trim() !== '' && !checkIfValueExists(currentValue));
    }
  }, [])

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setValue(name, value);

    // Update isNewValue status
    setIsNewValue(value.trim() !== '' && !checkIfValueExists(value));

    if (value.trim().length > 0) {
      const filtered = suggestions.filter((suggestion) =>
        suggestion.text.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredSuggestions(filtered);
      setShowSuggestions(filtered.length > 0);
    } else {
      setFilteredSuggestions([]);
      setShowSuggestions(false);
      setIsNewValue(false);
    }
  };

  const handleSelectSuggestion = (suggestion: AutocompleteSuggestion) => {
    setValue(name, suggestion.value);
    onMatch?.(suggestion);
    setShowSuggestions(false);
    setIsNewValue(false);
    trigger(name);
  };

  const handleBlur = () => {
    if (!isPrefix) {
      const currentValue = watch(name);
      if (currentValue) {
        const formattedValue = transformToLsdFormat(currentValue);
        setValue(name, formattedValue);
        setIsNewValue(!checkIfValueExists(formattedValue));
      }
    }
    setTimeout(() => {
      if (filteredSuggestions.length === 1) {
        handleSelectSuggestion(filteredSuggestions[0]);
      } else {
        setShowSuggestions(false);
      }
    }, 200);
    trigger(name);
  };

  const defaultRegisterOptions: any = {
    validate: {
      validField: validateField,
    },
    ...registerOptions
  };

  const isRequired = useMemo(() => {
    return registerOptions?.required !== undefined || defaultRegisterOptions?.required !== undefined;
  }, [registerOptions, defaultRegisterOptions]);

  const errorValue = formState?.errors?.[nameField];
  const errorMsg: any = (errorValue as any)?.lsd?.message || ""; // Hardcoded lsd field here, Do change if need

  const containerWidth = isPrefix ? (isSmallScreen ? "full" : "35%") : w;
  return (
    <FormControl isInvalid={!!formState.errors?.[nameField]} w={containerWidth}>
      <VStack gap="0px" alignItems="flex-start" position="relative">
        <FormLabel color="#455360">
          {label} {isRequired && <span style={{ color: 'red' }}>*</span>}
        </FormLabel>
        <InputGroup>
          <Input
            id={name}
            h="48px"
            borderRadius="4px"
            variant="filled"
            color="#0E1628"
            placeholder={placeholder}
            {...register(name, defaultRegisterOptions)}
            onChange={handleInputChange}
            onBlur={handleBlur}
            maxLength={isPrefix ? 3 : maxLength}
          />
          {isNewValue && (
            <InputRightElement width="4.5rem" h="48px">
              <Button h="1.75rem" size="xs" color="#3455FF" bgColor="white" cursor="none">
                New
              </Button>
            </InputRightElement>
          )}
        </InputGroup>

        {showSuggestions && (
          <Box position="absolute" top="95px" width="100%" zIndex="dropdown">
            <List bg="white" boxShadow="lg">
              {filteredSuggestions.map((suggestion) => (
                <ListItem
                  key={suggestion.value}
                  cursor="pointer"
                  onClick={() => handleSelectSuggestion(suggestion)}
                  px={4}
                  py={2}
                  _hover={{ bg: "gray.100" }}
                >
                  <Text>{suggestion.text}</Text>
                </ListItem>
              ))}
            </List>
          </Box>
        )}

        <FormHelperText>{helper}</FormHelperText>
        <FormErrorMessage>
          {errorMsg || null}
        </FormErrorMessage>
      </VStack>
    </FormControl>
  );
}