import { bolmintApi } from "../../app/api";
import { operations } from "../../app/api/schema";


export const coordinatesApi = bolmintApi.injectEndpoints({
  endpoints: (builder) => ({
    listCoordinates: builder.query<
      operations["list_lsd_values"]["responses"]["200"]["content"]["application/json"],
      void
    >({
      query: () => ({
        url: "/coordinate/lsd-list/",
      }),
      providesTags: ["coordinates"]
    }),
    createCoordinates: builder.mutation<
      operations["coordinate_create"]["responses"]["201"]["content"]["application/json"],
      Partial<
        operations["coordinate_create"]["requestBody"]["content"]["application/json"]
      >
    >({
      query: (resource) => ({
        url: `/coordinate/`,
        method: "POST",
        body: resource,
      }),
      invalidatesTags: ["coordinates", "terminals"]
    }),
    retrieveCoordinates: builder.query<
      operations["coordinate_retrieve"]["responses"]["200"]["content"]["application/json"],
      number
    >({
      query: (coordinatesId) => ({
        url: `/coordinate/${coordinatesId}/`,
      }),
    }),
    updateCoordinates: builder.mutation<
      operations["coordinate_update"]["responses"]["200"]["content"]["application/json"],
      operations["coordinate_update"]["requestBody"]["content"]["application/json"]
    >({
      query: (resource) => ({
        url: `/coordinate/${resource.id}/`,
        method: "PUT",
        body: resource,
      }),
    }),
    partialUpdateCoordinates: builder.mutation<
      operations["coordinate_partial_update"]["responses"]["200"]["content"]["application/json"],
      operations["coordinate_partial_update"]["requestBody"]["content"]["application/json"]
    >({
      query: (resource) => ({
        url: `/coordinate/${resource.id}/`,
        method: "PATCH",
        body: resource,
      }),
    }),
    destroyCoordinates: builder.mutation<void, number>({
      query: (coordinatesId) => ({
        url: `/coordinate/${coordinatesId}/`,
        method: "DELETE",
      }),
    }),
  }),
})

export const {
  useListCoordinatesQuery,
  useCreateCoordinatesMutation,
  useRetrieveCoordinatesQuery,
  useUpdateCoordinatesMutation,
  usePartialUpdateCoordinatesMutation,
  useDestroyCoordinatesMutation,
} = coordinatesApi
