import { Flex } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { TICKET_STATUS_OPTIONS, TIME_ENTRY_STATUS_OPTIONS } from '../../features/tickets/ticket_choices';

interface StatusBadgesProps {
    status: string
}

export function StatusBadges({ status }: StatusBadgesProps) {

    const pathName = useLocation().pathname
    const isTrackinPage = pathName.includes("/tracking")

    const statusOption = isTrackinPage ? TIME_ENTRY_STATUS_OPTIONS.find(option => option.value === status) : TICKET_STATUS_OPTIONS.find(option => option.value === status);
    const bgColor = statusOption?.bgColor;
    const color = statusOption?.color;
    const label = statusOption?.label;

    return (
        status && <Flex width="fit-content" minW={{ base: "120px", lg: "150px" }} h={"29px"} fontSize={{ base: "12px", lg: "14px" }} borderRadius={"40px"} bgColor={bgColor} color={color} justifyContent={"center"} alignItems={"center"} flexShrink={0}> {label}</Flex>
    )
}


