import "./index.css";
import "react-datepicker/dist/react-datepicker.css";
import 'react-responsive-pagination/themes/bootstrap.css';

import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { components } from "./app/api/schema";
import { useConfigCheck } from "./app/hooks";
import { store, persistor } from "./app/store";
import { ProtectedRoute } from "./ProtectedRoute";
import { AuthArea } from "./layout/areas/AuthArea";
import { LoginPage } from "./pages/auth/LoginPage";
import { UserArea } from "./layout/areas/UserArea";
import { DraftPage } from "./pages/user/DraftPage";
import { LogoutPage } from "./pages/auth/LogoutPage";
import { PrintArea } from "./layout/areas/PrintArea";
import { PrintDraft } from "./pages/print/PrintDraft";
import { ProfilePage } from "./pages/user/ProfilePage";
import { ChargesPage } from "./pages/user/ChargesPage";
import { TicketsPage } from "./pages/user/TicketsPage";
import { TrackingPage } from "./pages/user/TrackingPage";
import { ConfigProvider } from "./contaxt/ConfigProvider";
import { TicketEditPage } from "./pages/user/TicketEditPage";
import { DriverHomePage } from "./pages/user/DriverHomePage";
import { PersistGate } from "redux-persist/integration/react";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { TicketDetailPage } from "./pages/user/TicketDetailPage";
import { ResetPasswordPage } from "./pages/user/ResetPasswordPage";
import { StampTicketDetail } from "./pages/stamp/StampTicketDetail";
import { TicketDetailPrint } from "./pages/print/TicketDetailPrint";
import { ForgotPasswordPage } from "./pages/user/ForgotPasswordPage";
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";
import { ApproveSubmittedTimeEntries } from "./pages/user/ApproveSubmittedTimeEntries";

type roleEnum = components["schemas"]["RoleEnum"];

// Define roles as constants to avoid repetition
const userRoles: roleEnum[] = ['driver-lead', 'driver', 'app-administrator', 'finance', 'super-admin'];
const customerRole: roleEnum[] = ['customer'];
const workerRole: roleEnum[] = ['swamper'];

// Route helper function for protected routes
const protectedRoute = (
  Component: React.ComponentType<any>,
  roles: roleEnum[]
) => (< ProtectedRoute Component={Component} roles={roles} />);

// User Area Routes
const userRoutes = [
  { path: "home", element: protectedRoute(DriverHomePage, userRoles) },
  { path: "profile", element: <ProfilePage /> },
  { path: "draft/:id?", element: protectedRoute(DraftPage, userRoles) },
  { path: "draft/:id/charges", element: protectedRoute(ChargesPage, userRoles) },
  { path: "tracking", element: protectedRoute(TrackingPage, [...userRoles, ...workerRole]) },
  { path: "tickets", element: protectedRoute(TicketsPage, [...userRoles, ...customerRole]) },
  { path: "tickets/:id", element: protectedRoute(TicketDetailPage, userRoles) },
  { path: "tickets/:id/edit", element: protectedRoute(TicketEditPage, userRoles) },
  { path: "tickets/:id/charges", element: protectedRoute(ChargesPage, userRoles) },
  { path: "stamp/tickets/:id", element: protectedRoute(StampTicketDetail, customerRole) },
  { path: "approve-time-entries", element: protectedRoute(ApproveSubmittedTimeEntries, ['driver-lead', 'app-administrator', 'finance', 'super-admin']) },
];

// Print Area Routes
const printRoutes = [
  { path: "tickets/:ticketId/", element: <TicketDetailPrint /> },
  { path: "draft/:id", element: <PrintDraft /> },
];

// Auth Area Routes
const authRoutes = [
  { index: true, element: <Navigate to="login" /> },
  { path: "login", element: <LoginPage /> },
  { path: "logout", element: <LogoutPage /> },
  { path: "forgot-password", element: <ForgotPasswordPage /> },
  { path: "reset-password/:token?", element: <ResetPasswordPage /> },
];


// A helper component for role-based redirection
const UserRedirect = () => {
  const { userProfile: { role } = {} } = useConfigCheck();
  if (role === "swamper") {
    return <Navigate to="tracking" />;
  }
  if (role === "customer") {
    return <Navigate to="tickets" />;
  }
  return <Navigate to="home" />;
};

// Create Browser Router with optimized route definitions
const router = createBrowserRouter([
  {
    path: "/user",
    element: <UserArea />,
    children: [
      { index: true, element: <UserRedirect /> },
      ...userRoutes,
    ],
  },
  {
    path: "/print",
    element: <PrintArea />,
    children: printRoutes,
  },
  {
    path: "/auth",
    element: <AuthArea />,
    children: authRoutes,
  },
  {
    path: "*",
    element: <Navigate to="/user/home" />,
  },
]);

// Define Chakra UI theme
const customTheme = extendTheme({
  fonts: {
    body: `Inter, sans-serif`,
  },
});

// Render the app
ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ChakraProvider theme={customTheme}>
          <ConfigProvider>
            <RouterProvider router={router} />
          </ConfigProvider>
        </ChakraProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);